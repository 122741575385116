import React from 'react';
import IntroText from '../IntroText/IntroText';
import BestPlaceToCode from './BestPlaceToCode';
import Comprometemos from './Comprometemos';
import Principios from './Principios';
import Buscamos from './Buscamos';
import { Container, Box } from '@material-ui/core';

import background from '../../assets/images/unete/bg.svg';
import unete from '../../assets/images/unete/unete.png';

const Unete = () => {
    return (
        <>  <Box style={{
                backgroundImage: 'url('+ background +')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'left 1200px',
                backgroundSize: '100% auto'
            }}>
                <Container maxWidth="lg">
                    <IntroText
                        marginTop="118px"
                        subtitle="Te esperamos"
                        title="Únete a Nova"
                        normalParagraph="En Nova nuestra principal experiencia  se basa en fusionar el conocimiento de trabajar con grandes instituciones y la agilidad que se obtiene en fintechs o empresas nativas digitales. "
                        boldParagraph="“Aceleramos la adopción digital en el sector financiero en México”."
                        image={ unete }
                        alt="Únete a Nova"
                    />
                    <Box mt="280px"></Box>
                    <Box id="bestplacetocode">
                        <BestPlaceToCode />
                    </Box>
                    <Box mt="280px"></Box>
                    <Comprometemos />
                    <Box pt="280px"></Box>
                    <Principios />
                    <Box pt="280px"></Box>
                    <Box id="Buscamos-profesionales">
                        <Buscamos />
                    </Box>
                    <Box pb="400px"></Box>
                </Container>
            </Box>
        </>
    )
}

export default Unete