import React from 'react'
import Heading from '../Heading/Heading';
import { Box, Grid } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Styles from './Alianzas.module.scss';
import adobe from '../../assets/images/nosotros/adobe-logo.png';
import scrum from '../../assets/images/nosotros/scrum-alliance.svg';
import google from '../../assets/images/nosotros/google-logo.png';
import microsoft from '../../assets/images/nosotros/microsoft-logo.png';
import aws from '../../assets/images/nosotros/aws-logo.png';
import openbank from '../../assets/images/nosotros/openbank-logo.png';
import istqb from '../../assets/images/nosotros/istqb-logo.png';
import transmit from '../../assets/images/nosotros/transmit-logo.png';

const Alianzas = () => {
    const matches = useMediaQuery('(min-width:960px)');

    return (
        <>
            <Heading 
                subtitle="Estratégicamente seleccionados"
                title="Nuestras Alianzas"
                separator={ true }         
            />
            <Grid container spacing={2} alignItems="center">
                <Grid item lg={3} md={3} sm={12} xs={12}>
                    <h4 className={Styles.title}>Diseño</h4>
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12} align={ matches ? 'center' : 'left'}>
                    <img src={adobe} alt="Adobe" className={Styles.image} />
                </Grid>
            </Grid>  
            <Grid container spacing={2} alignItems="center">
                <Grid item lg={3} md={3} sm={12} xs={12}>
                    <h4 className={Styles.title}>Agile</h4>
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12} align={ matches ? 'center' : 'left'}>
                    <img src={scrum} alt="Scrum Alliance" className={Styles.image} />
                </Grid>
            </Grid>  
            <Grid container spacing={2} alignItems="center">
                <Grid item lg={3} md={3} sm={12} xs={12}>
                    <h4 className={Styles.title}>Servicios Cloud</h4>
                </Grid>
                <Grid item lg={3} md={3} sm={4} xs={12} align={ matches ? 'center' : 'left'}>
                    <img src={google} alt="Google" className={Styles.image} />
                </Grid>
                <Grid item lg={3} md={3} sm={4} xs={12} align={ matches ? 'center' : 'left'}>
                    <img src={microsoft} alt="Microsoft" className={Styles.image} />
                </Grid>
                <Grid item lg={3} md={3} sm={4} xs={12} align={ matches ? 'center' : 'left'}>
                    <img src={aws} alt="AWS" className={Styles.image} />
                </Grid>
            </Grid>  
            <Grid container spacing={2} alignItems="center">
                <Grid item lg={3} md={3} sm={12} xs={12}>
                    <h4 className={Styles.title}>API Cloud Native</h4>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12} align={ matches ? 'center' : 'left'}>
                    <img src={openbank} alt="Open Bank Project" className={Styles.image} />
                </Grid>
            </Grid>  
            <Grid container spacing={2} alignItems="center">
                <Grid item lg={3} md={3} sm={12} xs={12}>
                    <h4 className={Styles.title}>Resting</h4>
                </Grid>
                <Grid item lg={3}  md={3} sm={6} xs={12} align={ matches ? 'center' : 'left'}>
                    <img src={istqb} alt="International Software Testing Qualificatitions Board" className={Styles.image} />
                </Grid>
            </Grid>  
            <Grid container spacing={2} alignItems="center">
                <Grid item lg={3} md={3} sm={12} xs={12} >
                    <h4 className={Styles.title}>Seguridad</h4>
                </Grid>
                <Grid item lg={3}  md={3} sm={12} xs={12} align={ matches ? 'center' : 'left'}>
                    <img src={transmit} alt="Transmit Security" className={Styles.image} />
                </Grid>
            </Grid>   
            <Box mb="666px"></Box>
        </>
    )
}

export default Alianzas
