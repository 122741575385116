import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { ReactComponent as Logo } from '../../assets/images/logo-color.svg';
import Styles from './Header.module.scss';

import facebookIcon from '../../assets/images/social_networks/facebook-icon.svg';
import twitterIcon from '../../assets/images/social_networks/twitter-icon.svg';
import linkedinIcon from '../../assets/images/social_networks/linkedin-icon.svg';

const Header = () => {
    const location = useLocation();
    const matches = useMediaQuery('(min-width:800px)');

    return (
        <>

        { location.pathname !== '/contactanos' && 
            <Container maxWidth="lg">
                <Grid container spacing={2} alignItems="center">
                    <Grid item lg={6} md={5} sm={4} xs={4}>
                        <Link to="/#">
                            <Logo title="Nova a Reboot Company" className={ !matches ? Styles.small : Styles.logo} />
                        </Link>
                    </Grid>
                    <Grid item lg={6} md={7} sm={8} xs={8} align="right">
                        <nav className={matches ? Styles.navigation : Styles.mobileNav }>
                            <ul>
                                <li>
                                    <Link className={ location.pathname === '/' ? Styles.active : undefined} to="/" title="Home">Home</Link>
                                </li>
                                <li>
                                    <Link className={ location.pathname === '/soluciones' ? Styles.active : undefined} to="/soluciones" title="Soluciones">Soluciones</Link>
                                </li>
                                <li>
                                    <Link className={ location.pathname === '/nosotros' ? Styles.active : undefined} to="/nosotros" title="Nosotros">Nosotros</Link>
                                </li>
                                <li>
                                    <Link className={ location.pathname === '/unete' ? Styles.active : undefined} to="/unete" title="Únete a Nova">Únete a Nova</Link>
                                </li>
                                <li>
                                    <Link className={ location.pathname === '/contactanos' ? Styles.active : undefined} to="/contactanos" title="Contáctanos">Contáctanos</Link>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/novasolutionsystems" target="_blank" rel="noreferrer"><img className={Styles.icons} src={facebookIcon} alt="Nova Facebook"/></a>
                                    <a href="https://twitter.com/novasolutionsys" target="_blank" rel="noreferrer"><img className={Styles.icons} src={twitterIcon} alt="Nova Twitter"/></a>
                                    <a href="https://www.linkedin.com/company/novasolutionsystems" target="_blank" rel="noreferrer"><img className={Styles.icons} src={linkedinIcon} alt="Nova LinkedIn"/></a>
                                </li>
                            </ul>
                        </nav>
                    </Grid>
                </Grid>

            </Container>
        }
        </>
    )
}

export default Header;