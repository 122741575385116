import React from 'react';
import IntroText from '../IntroText/IntroText';
import Caracteristicas from './Caracteristicas';
import Diferenciadores from './Diferenciadores';
import Funcionalidades from './Funcionalidades';
import { Container, Box, Button } from '@material-ui/core';

import background from '../../assets/images/ndbank/bg.svg';
import ndbank from '../../assets/images/ndbank/nd.png';
import ndbankPDF from '../../assets/pdf/NDBank.pdf';

const Ndbank = () => {
    return (
        <>  
            <Box pt="118px" style={{
                backgroundImage: 'url('+ background +')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% auto',
                backgroundPosition: 'left 796px'
            }}>
                <Container maxWidth="lg">
                    <IntroText
                        marginTop="104px"
                        subtitle="Banca digital Web y móvil"
                        title="Nova Digital Bank"
                        separator={ true }
                        bold={ true }
                        normalParagraph="<strong>Es nuestro framework de banca digital web y móvil para banca de consumo y empresarial.</strong><ul><li>Una solución plug and play.</li><li>Con enfoque customer centric.</li><li>Cumple regulatoriamente con la CNBV</li></ul>"
                        image={ ndbank }
                        alt="Banca digital Web y móvil Nova Digital Bank"
                    />
                    <Button variant="contained" color="primary" className="button-md" href={ndbankPDF} target='_blank' disableElevation>Descargar Brochure ND Bank en PDF</Button>
                    <Box mb="253px"></Box>
                    <Caracteristicas />
                    <Box mb="189px"></Box>
                    <Diferenciadores />
                    <Box mb="267px"></Box>
                    <Funcionalidades />
                    <Box mb="168px"></Box>
                </Container>
            </Box>
        </>
    )
}

export default Ndbank
