import React from 'react';
import Heading from '../Heading/Heading';
import { Grid, Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Styles from '../Ndbank/Caracteristicas.module.scss';
import experiencias from '../../assets/images/ndbank/experiencia.svg';
import plataforma from '../../assets/images/ndbank/plataforma.svg';
import experiencia from '../../assets/images/ndbank/integracion.svg';
import cloud from '../../assets/images/ndbank/cloudnative.svg';
import integracion from '../../assets/images/rao/integracion.svg';
import analytics from '../../assets/images/ndbank/analytics.svg';

const Caracteristicas = () => {
    const matches = useMediaQuery('(min-width:960px)');

    return (
        <>
            <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12}>
                    <Grid container spacing={2}>
                        <Grid item lg={10} sm={12}>
                            <Heading
                                subtitle="Nova RAO"
                                title="Características"
                                separator={ true }
                                description="Diseñe y automatice sus procesos de onboarding, apertura de cuentas, originación y procesos de auto-servicio a través de múltiples canales"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={12}>
                            <Box mt={ matches && '140px' }></Box>
                            <Box className={Styles.card} >
                                <img src={experiencias} alt="Experiencias Excepcionales" />
                                <h4>Experiencias Excepcionales</h4>
                                <p>Cree Experiencias Excepcionales para Clientes utilizando mejores prácticas y sistemas probados.</p>
                            </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12}>
                        <Box mt={ matches && '80px' }></Box>
                        <Box className={Styles.card} >
                            <img src={plataforma} alt="Plataforma Abierta" />
                            <h4>Plataforma Abierta</h4>
                            <p>Construya sobre una Plataforma Abierta, en lugar que desde cero, sin comprometerse por años a una solución empaquetada.</p>
                        </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={3} md={3} sm={12}>
                <Box mt={ matches && '180px' }></Box>
                    <Box className={Styles.card} >
                        <img src={experiencia} alt="Experiencia Consistente" />
                        <h4>Experiencia Consistente</h4>
                        <p>Integrado como una sola plataforma, durante todo el ciclo de vida de sus clientes digitales, para presentar una Experiencia Consistente al cliente desde la adquisición hasta la retención (Cuando se usa ND Banking).</p>
                    </Box>
                    <Box className={Styles.card}>
                        <img src={cloud} alt="Cloud Native" />
                        <h4>Cloud Native</h4>
                        <p>Solución Cloud-Native, para reducir el TCO, y escalar basado en el crecimiento, cumpliendo las regulaciones mexicanas, corriendo en nubes dentro del territorio nacional.</p>
                    </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={12}>
                <Box mt={ matches && '66px' }></Box>
                <Box className={Styles.card} >
                        <img src={integracion} alt="Integración Mínima" />
                        <h4>Integración Mínima</h4>
                        <p>Integración Mínima con los sistemas Core del banco, permite una implementación ágil, sin reemplazar los sistemas actuales.</p>
                    </Box>
                    <Box className={Styles.card}>
                        <img src={analytics} alt="Analytics Integración" />
                        <h4>Analytics Integración</h4>
                        <p>Descubra con Analytics como mejorar sus resultados - más personalización, más cross-sell y up-sell, maximizando las conversiones.</p>
                    </Box>
                </Grid>
            </Grid>   
        </>
    )
}

export default Caracteristicas
