import React from 'react';
import { Box, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Styles from './IntItem.module.scss'

const IntItem = (props) => {
    const matches = useMediaQuery('(min-width:960px)');

    return (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item lg={6} md={8} sm={12}>
                    <Box className={Styles.card}
                    dangerouslySetInnerHTML={{__html: props.content }}></Box>
                </Grid>
                <Grid item lg={6} md={4} sm={12}>
                    <img src={props.image} className={Styles.image} alt={props.alt} />
                </Grid>
            </Grid>
            { !matches && <Box mt="200px"></Box> }
        </>
    )
}

export default IntItem
