import React from 'react'
import Heading from '../Heading/Heading';
import { HashLink as Link } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import { Container, Grid, Box, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Styles from './Soluciones.module.scss';
import solucionesIcon from '../../assets/images/home/icon-soluciones.svg';
import chat from '../../assets/images/chatbots/conversacionales.svg';
import qr from '../../assets/images/codi/qr.svg';
import cloud from '../../assets/images/ndbank/cloudnative.svg';
import open from '../../assets/images/ndbank/integracion.svg';

const Soluciones = () => {
    const location = useLocation();
    const matches = useMediaQuery('(min-width:960px)');

    return (
        <Box pt="180px" pb="264px" className={ location.pathname === '/' ? Styles.background : undefined}>
            <Container maxWidth="lg">
                <Grid container spacing={2} justifyContent="space-between">
                <Grid item lg={6} md={6} sm={5} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item lg={10} md={10} sm={12}>
                            <Heading
                                subtitle="Somos expertos"
                                title="Nuestras soluciones"
                                description="Nuestra experiencia nos ha permitido generar soluciones pensadas para atender las necesidades digitales del sector financiero."
                                bold={ false }
                                separator={ true }
                                margin={ '94px' }
                            />
                        </Grid>
                    </Grid>
                    <Box mt="33px">
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item lg={4} md={5} sm={9} xs={6}>
                                <Button component={Link} to="/soluciones#" color="primary" className="button-md" variant="contained" disableElevation fullWidth>
                                    Ver más
                                </Button>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12}>
                                <div className={Styles.card}>
                                    <img src={cloud} alt="RAO" />
                                    <h4>RAO</h4>
                                    <p>Solución para crear procesos de adquisición dinámicos, que cumplen con todos los requerimientos de la regulación mexicana.</p>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={5} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={12}>
                            <Box mt={ matches ? '110px' : '0'}></Box>
                            <Box className={Styles.card} >
                            <img src={solucionesIcon} alt="ND Bank" />
                                <h4>ND Bank</h4>
                                <p>Plataforma de banca digital Personal o Empresarial lista para usarse. </p>
                            </Box>
                            <div className={Styles.card}>
                            <img src={qr} alt="SDK CoDi" />
                                <h4>SDK CoDi</h4>
                                <p>Solución que se integra con los servicios de transferencia de la Institución Bancaria así como con los servicios de BANXICO.</p>
                            </div>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12}>
                            <div className={Styles.card}>
                            <img src={open} alt="Open Banking" />
                                <h4>Open Banking</h4>
                                <p>Solución líder de API bancario basada en la regulación Europea. Somos Partners de TESOBE OpenBankProject. </p>
                            </div>
                            <div className={Styles.card}>
                            <img src={chat} alt="Chatbots" />
                                <h4>Chatbots</h4>
                                <p>Asistentes para realizar operaciones en diversos canales como Web, Facebook, WhatsApp, Messenger, Twitter o chat personalizados. </p>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </Container>
        </Box>
    )
}

export default Soluciones