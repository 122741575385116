import React from 'react';
import Heading from '../Heading/Heading';
import { Grid, Box } from '@material-ui/core';

import Styles from './Sdk.module.scss';

const Sdk = () => {
    return (
        <>
            <Heading
                subtitle="¿Qué es?"
                title="SDK CoDi"
                separator={ true }
            />
            <Box mt="80px"></Box>
            <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={12}>
                    <Box className={Styles.card}>
                        <h4>Componente<br />de Software</h4>
                        <p>Para sistemas operativos móviles como iOS y/o Android diseñado para realizar las comunicaciones entre un app móvil y las APIs de Banxico.</p>
                    </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={12}>
                    <Box className={Styles.card}>
                        <h4>Diseño fácil<br />de integrar</h4>
                        <p>Su diseño le permite ser un componente de software aislado que se puede integrar fácilmente con cualquier aplicación móvil que soporte iOS 11 o superior y Android 5 o superior.</p>
                    </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={12}>
                    <Box className={Styles.card}>
                        <h4>Funcionalidad<br />de BANXICO</h4>
                        <p>Señalada el documento de especificaciones de CoDi 4.6 y solamente requiere que se le pasen los datos de cada institución como esta determine que es el modo más seguro.</p>
                    </Box>
                </Grid>
            </Grid>   
        </>
    )
}

export default Sdk
