import React from 'react'
import Heading from '../Heading/Heading';
import { Box, Grid } from '@material-ui/core';

import aceleramos from '../../assets/images/home/aceleramos.png';

const Somos = () => {
    return (
        <Box mt="120px">
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item lg={5} md={5} sm={12}>
                <Heading
                    subtitle="Somos Nova"
                    title="Aceleramos la adopción digital en el sector financiero mexicano"
                    description="¡El éxito de nuestros clientes es nuestro éxito!"
                    bold={ true }
                    separator={ false }
                    button="Nuestras soluciones"
                    to="/soluciones#soluciones"
                    margin={ '102px' }
                />
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                <img className="long-shadow" style={{width:'100%'}} src={aceleramos} alt="Aceleramos la adopción digital en el sector financiero mexicano" />
                </Grid>
            </Grid>
        </Box>
    )
}

export default Somos
