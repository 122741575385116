import React from 'react';
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';
import Data from '../../assets/seo/data';
import logo from '../../assets/images/logo-color.svg';

const Seo = () => {
    const location = useLocation();
    const Section = Data.find(data => data.section === location.pathname ) || Data[Data.length-1];
    
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{ Section.title } | Nova Solutions Systems</title>
            <meta name="description" content={ Section.description } />
        
            <link rel="canonical" href="https://novasolutionsystems.com" />

            <meta property="og:title" content={Section.title } />
            <meta property="og:description" content={ Section.description } />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://novasolutionsystems.com" />
            <meta property="og:image" content={ logo } />
        </Helmet>
    )
}

export default Seo;
