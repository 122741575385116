import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import { useHistory} from 'react-router-dom';
import { Container, Box, Grid, Button, Backdrop } from '@material-ui/core';

import Styles from './Contactanos.module.scss';
import location from '../../assets/images/ico-location.svg';
import email from '../../assets/images/ico-mail.svg';
import close from '../../assets/images/contactanos/icon-close.svg';
import closeBlack from '../../assets/images/contactanos/icon-close-black.svg';
import mobile from '../../assets/images/icon-mobile.svg';
import { makeStyles } from '@material-ui/core/styles';
import { Widget } from '@typeform/embed-react'

import facebookIcon from '../../assets/images/social_networks/facebook-icon.svg';
import twitterIcon from '../../assets/images/social_networks/twitter-icon.svg';
import linkedinIcon from '../../assets/images/social_networks/linkedin-icon.svg';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

const Contactanos = () => {
    const history = useHistory();
    const { register, reset, formState: { errors }, handleSubmit } = useForm();
    const onSubmit = (data) => emailSent(data)

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    }
    const emailSent = (object) => {
        reset()
        setOpen(!open);
    }
    return (
        <>
            <Backdrop className={classes.backdrop} open={open}>
                <Grid container spacing={2}>
                    <Grid item lg={4}></Grid>
                    <Grid item lg={4}>
                        <Box className={Styles.card} align="right">
                            <img src={closeBlack} alt="Cerrar" title="cerrar" onClick={handleClose} />
                            <Box align="center">
                                <p><strong>Tu mensaje fue enviado</strong></p>
                                <p>Nos pondremos en contacto lo más pronto posible.</p>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Backdrop>
            <Box className={Styles.contact} pt="70px">
                
                <Container maxWidth="lg">
                    <img onClick={() => history.goBack()} src={close} alt="Cerrar" title="Cerrar" className={Styles.close} />
                    <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={12}>
                            <Grid container spacing={2}>
                                <Grid item lg={8} md={12}>
                                    <h2 className={Styles.subtitle}>CONTÁCTENOS</h2>
                                    <h1 className={Styles.title}>Queremos ser su socio estratégico</h1>
                                    <span className={Styles.separator}></span>
                                    <p className={Styles.text}>¡El éxito de nuestros clientes es nuestro éxito!</p>
                                    <ul className={Styles.ulIcon}>                                
                                        <li>
                                            <a href="https://www.facebook.com/novasolutionsystems" target="_blank" rel="noreferrer"><img className={Styles.icons} src={facebookIcon} alt="Nova Facebook"/></a>
                                            <a href="https://twitter.com/novasolutionsys" target="_blank" rel="noreferrer"><img className={Styles.icons} src={twitterIcon} alt="Nova Twitter"/></a>
                                            <a href="https://www.linkedin.com/company/novasolutionsystems" target="_blank" rel="noreferrer"><img className={Styles.icons} src={linkedinIcon} alt="Nova LinkedIn"/></a>
                                        </li>
                                    </ul>
                                    <Box display="flex" alignItems="flex-start" mb="24px" className={Styles.address}>
                                        <img src={mobile} alt="Teléfono" />
                                        <p><small>
                                            +52 (55) 4172 8497
                                        </small></p>
                                    </Box>
                                    <Box display="flex" alignItems="flex-start" mb="24px" className={Styles.address}>
                                        <img src={location} alt="Dirección" />
                                        <p><small>
                                        Torre Axxa , Xola 535, piso 29, Col del Valle Nte,<br />
                                        Benito Juárez, 03103 Ciudad de México, CDMX
                                        </small></p>
                                    </Box>
                                    <Box display="flex" alignItems="flex-start" className={Styles.address}>
                                        <img src={email} alt="Correo electrónico" />
                                        <p><small>
                                            <a 
                                                href="mailto:contacto@novasolutionsystems.com?subject=Solicitud de información&body=Que tal, Visité su sitio web y me gustaría conocer más sobre sus servicios."
                                                title="Correo electrónico"
                                            >contacto@novasolutionsystems.com</a>
                                        </small></p>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} container spacing={2}>
                        <Widget id="KrCDgPqR" style={{ width: '100%' }} className="my-form" />
                            {/* <form className={Styles.form} onSubmit={handleSubmit(onSubmit)}>
                                <label htmlFor="fullname">Nombre completo</label>
                                <input 
                                    className={ errors.fullname?.type === 'required' ? Styles.error : undefined}
                                    placeholder="Ingrese su numbre completo"
                                    type="text"
                                    name="fullname"
                                    {...register("fullname", { required: true })}
                                />
                                { errors.fullname?.type === 'required' && <p className={Styles.errorText}>Ingresa tu nombre completo</p>}

                                <label htmlFor="role">Puesto</label>
                                <input 
                                    className={ errors.role?.type === 'required' ? Styles.error : undefined}
                                    placeholder="Puesto o cargo en su empresa" 
                                    type="text" 
                                    name="role"
                                    {...register("role", { required: true})}
                                />
                                { errors.role?.type === 'required' && <p className={Styles.errorText}>Ingresa tu cargo o puesto</p>}

                                <Grid container spacing={2}>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                        <label htmlFor="email">Correo electrónico</label>
                                        <input
                                            className={ errors.email?.type === 'required' ? Styles.error : undefined}
                                            placeholder="Correo electrónico" 
                                            type="email"
                                            name="email"
                                            {...register("email", {required: true})}
                                        />
                                        { errors.email?.type === 'required' && <p className={Styles.errorText}>Ingresa tu correo electrónico</p>}
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <label htmlFor="phone">Teléfono (opcional)</label>
                                        <input
                                            placeholder="00 0000 0000"
                                            type="number"
                                            name="phone"
                                            {...register("phone", { min: 1000000000, max: 9999999999 })}
                                        />
                                        { errors.min > 999999999 && errors.min > 10000000000 && <p className={Styles.errorText}>Ingresa tu teléfono a 10 dígitos</p>}
                                    </Grid>
                                </Grid>
                                <label htmlFor="message">Mensaje</label>
                                <textarea
                                    className={ errors.message?.type === 'required' ? Styles.error : undefined}
                                    placeholder="¿En que podemos ayudarle?"
                                    name="message"
                                    {...register("message", {required: true})}
                                />
                                { errors.message?.type === 'required' && <p className={Styles.errorText}>Ingresa un mensaje</p>}
                                <Grid container spacing={2}>
                                    <Grid item lg={4} md={4} sm={6} xs={12}>
                                        <Button 
                                            className="button-contact" 
                                            variant="contained" 
                                            disableElevation 
                                            fullWidth 
                                            color="primary"
                                            type="submit"
                                            id="contact_form_btn"
                                        >Enviar</Button>
                                    </Grid>
                                </Grid>
                                <Box mb="24px"></Box>
                            </form> */}
                        </Grid>
                    </Grid>
                </Container>          
            </Box>
        </>
    )
}

export default Contactanos