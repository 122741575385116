import React from 'react'
import Heading from '../Heading/Heading';
import { Grid, Box } from '@material-ui/core';
import Styles from './Soluciones.module.scss';
import a from './Prensa.module.scss';

const Prensa = () => {
    return (
        <>
            <Box mb="285px">
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12}>
                        <Heading
                            subtitle="Noticias y actualizaciones"
                            title="Prensa"
                            separator={ false }
                            bold={ false }
                        />
                        <Grid container spacing={2}>
                            <Grid  item lg={3} md={3} sm={12}>
                                <Box className={Styles.card} >
                                    <h4>Potencia Open Finance competencia e inclusión.</h4>
                                    <p>Reforma.</p>
                                    <a href='https://www.reforma.com/potencia-open-finance-competencia-e-inclusion/ar2289876'>Nota completa</a>
                                </Box>
                            </Grid>
                            <Grid  item lg={3} md={3} sm={12}>
                                <Box className={Styles.card} >
                                    <h4>Potencia Open Finance competencia e inclusión.</h4>
                                    <p>El Norte.</p>
                                    <a href='https://www.elnorte.com/potencia-open-finance-competencia-e-inclusion/ar2290033'>Nota completa</a>
                                </Box>
                            </Grid>
                            <Grid  item lg={3} md={3} sm={12}>
                                <Box className={Styles.card} >
                                    <h4>Potencia Open Finance competencia e inclusión.</h4>
                                    <p>Mural.</p>
                                    <a href='https://www.mural.com.mx/potencia-open-finance-competencia-e-inclusion/ar2290041'>Nota completa</a>
                                </Box>
                            </Grid>
                            <Grid  item lg={3} md={3} sm={12}>
                                <Box className={Styles.card} >
                                    <h4>¿Cómo proteger nuestra información en 202 de un ciberataque?</h4>
                                    <p>MVS Noticias.</p>
                                    <a href='https://mvsnoticias.com/podcasts/juan-manuel-jimenez/tecnomadas-como-proteger-nuestra-informacion-en-2022-de-un-ciber-ataque/'>Nota completa</a>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                        <Grid  item lg={3} md={3} sm={12}>
                                <Box className={Styles.card} >
                                    <h4>Open Banking: la nueva realidad financiera.</h4>
                                    <p>Hablemos de Dinero.</p>
                                    <a href='https://www.hablemosdedinero.com.mx/nota/open-banking-la-nueva-realidad-financiera'>Nota completa</a>
                                </Box>
                            </Grid>
                            <Grid  item lg={3} md={3} sm={12}>
                                <Box className={Styles.card} >
                                    <h4>Computación de alto rendimiento en sector financiero.</h4>
                                    <p>Hablemos de Dinero.</p>
                                    <a href='https://www.hablemosdedinero.com.mx/nota/computacion-de-alto-rendimiento-en-el-sector-financiero'>Nota completa</a>
                                </Box>
                            </Grid>
                            <Grid  item lg={3} md={3} sm={12}>
                                <Box className={Styles.card} >
                                    <h4>Inteligencia Artificial en el sector financiero.</h4>
                                    <p>Hablemos de Dinero.</p>
                                    <a href='https://www.hablemosdedinero.com.mx/nota/inteligencia-artificial-en-el-sector-financiero'>Nota completa</a>
                                </Box>
                            </Grid>
                            <Grid  item lg={3} md={3} sm={12}>
                                <Box className={Styles.card} >
                                    <h4>Banca requiere elevar nivel de digitalización sin disminuir seguridad.</h4>
                                    <p>El Financiero.</p>
                                    <a href='https://www.elfinanciero.com.mx/economia/2022/03/24/banca-requiere-elevar-nivel-de-digitalizacion-sin-disminuir-seguridad/?outputType=amp'>Nota completa</a>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default Prensa
