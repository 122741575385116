import React from 'react';
import Heading from '../Heading/Heading';
import { Grid, Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Styles from './Caracteristicas.module.scss';
import cloud from '../../assets/images/ndbank/cloudnative.svg';
import integracion from '../../assets/images/ndbank/integracion.svg';
import analytics from '../../assets/images/ndbank/analytics.svg';
import experiencia from '../../assets/images/ndbank/experiencia.svg';
import omni from '../../assets/images/ndbank/omnicanal.svg';
import drag from '../../assets/images/ndbank/dragdrop.svg';
import plataforma from '../../assets/images/ndbank/plataforma.svg';

const Caracteristicas = () => {
    const matches = useMediaQuery('(min-width:960px)');

    return (
        <>
            <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12}>
                    <Heading
                        subtitle="Somos expertos"
                        title="Características"
                        separator={ true }
                    />
                    <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={12}>
                            <Box mt={ matches && '140px'}></Box>
                            <Box className={Styles.card} >
                                <img src={cloud} alt="Cloud Native" />
                                <h4>Cloud Native</h4>
                                <p>Solución Cloud-Native, para reducir el TCO, y escalar basado en el crecimiento, cumpliendo las regulaciones mexicanas, corriendo en nubes dentro del territorio nacional.</p>
                            </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12}>
                        <Box mt={ matches && '80px'}></Box>
                        <Box className={Styles.card} >
                            <img src={integracion} alt="Integración mínima" />
                            <h4>Integración mínima</h4>
                            <p>con los sistemas Core del banco, permite una implementación ágil, sin reemplazar los sistemas actuales.</p>
                        </Box>
                        <Box className={Styles.card}>
                            <img src={omni} alt="Omnicanalidad y Disponibilidad" />
                            <h4>Omnicanalidad y Disponibilidad</h4>
                            <p>En cual quier dispositivo y touchpoint  de la marca,</p>
                        </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={3} md={3} sm={12}>
                <Box mt={ matches && '140px'}></Box>
                    <Box className={Styles.card} >
                        <img src={analytics} alt="Analytics" />
                        <h4>Analytics</h4>
                        <p>Descubra con Analytics como mejorar sus resultados - más personalización, más cross-sell y up-sell, maximizando las conversiones. </p>
                    </Box>
                    <Box className={Styles.card}>
                        <img src={drag} alt="Drag & Drop" />
                        <h4>Drag & Drop</h4>
                        <p>Nova Digital Bank permite arrastrar los contenidos o widgets, desde el repositorio, para personalizar cada configuración.</p>
                    </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={12}>
                <Box mt={ matches && '66px'}></Box>
                <Box className={Styles.card} >
                        <img src={experiencia} alt="Experiencia Consistente" />
                        <h4>Experiencia Consistente</h4>
                        <p>Integrado como una sola plataforma, durante todo el ciclo de vida de sus clientes digitales, para presentar una Experiencia Consistente al cliente desde la adquisición hasta la retención (Cuando se necesite Nova RAO).</p>
                    </Box>
                    <Box className={Styles.card}>
                        <img src={plataforma} alt="Plataforma Abierta" />
                        <h4>Plataforma Abierta</h4>
                        <p>Construya sobre una Plataforma Abierta, en lugar que desde cero, sin comprometerse por años a una solución empaquetada.</p>
                    </Box>
                </Grid>
            </Grid>   
        </>
    )
}

export default Caracteristicas
