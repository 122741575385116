import React from 'react';
import IntroText from '../IntroText/IntroText';
import Conversacionales from './Conversacionales';
import Caracteristicas from './Caracteristicas';
import{ Box, Container } from '@material-ui/core';

import chatbots from '../../assets/images/chatbots/chatbots.png';
import background from '../../assets/images/codi/bg.svg';

const Chatbots = () => {
    return (
        <>
            <Box pt="118px" pb="296px" style={{
                backgroundImage: 'url('+ background +')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 72%',
                backgroundPosition: 'left 796px'
            }}>
                <Container maxWidth="lg">
                    <IntroText
                        marginTop="140px"
                        subtitle="Sector Financiero"
                        title="ChatBots"
                        normalParagraph="<strong>Nuestro  Chatbot ha sido entrenado principalmente para llevar a cabo las funciones de atención al cliente del sector financiero.</strong>"
                        image={chatbots}
                        alt="ChatBots"
                    />
                    <Box mt="369px"></Box>
                    <Conversacionales />
                    <Box mt="269px"></Box>
                    <Caracteristicas />
                </Container>
            </Box>
        </>
    )
}

export default Chatbots