import React from 'react'
import Heading from '../Heading/Heading';
import { Grid, Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Styles from './Comprometemos.module.scss';
import gal1 from '../../assets/images/unete/galeria1.png';
import gal2 from '../../assets/images/unete/galeria2.png';
import gal3 from '../../assets/images/unete/galeria3.png';
import gal4 from '../../assets/images/unete/galeria4.png';
import gal5 from '../../assets/images/unete/galeria5.png';
import gal6 from '../../assets/images/unete/galeria6.png';
import gal7 from '../../assets/images/unete/galeria7.png';
import gal8 from '../../assets/images/unete/galeria8.png';
import gal9 from '../../assets/images/unete/galeria9.png';

const Comprometemos = () => {
    const matches = useMediaQuery('(min-width:960px)');

    return (
        <>
            <Grid container spacing={4} justifyContent="space-between">
                <Grid item lg={8} md={6} sm={12}>
                    <Heading
                        margin="66px"
                        subtitle="en Nova"
                        title="Nos comprometemos "
                        separator={ true }
                        description="4 ejes que son primordiales para todos e Nova."
                    />
                </Grid>
                <Grid item lg={4} md={4} sm={12}>
                    <Box className={Styles.card}>
                        <h4>Equilibrio personal y profesional</h4>
                        <p>En Nova estamos preocupados por incrementar la creatividad, el bienestar y felicidad  de nuestros consultores, además de potenciar la interacción y la comunicación, es por eso que Nova  es el mejor lugar para tu crecimiento personal y profesional.</p>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={4} justifyContent="flex-end">
                <Grid item lg={4} md={4} sm={12}>
                    <Box className={Styles.card}>
                        <h4>Seguridad</h4>
                        <p>En Nova estamos comprometidos en propiciar un ambiente de trabajo libre de violencia y un entorno seguro, trabajando de manera transparente, honesta e íntegra.</p>
                    </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={12}>
                    <Box mt={ matches && '-120px'}></Box>
                    <Box className={Styles.card} >
                        <h4>Diversidad, Igualdad e Inclusión</h4>
                        <p>En Nova estamos comprometidos en mantener un ambiente de respeto y apoyo, viviendo una cultura empresarial de igualdad de oportunidades, equidad, libre de discriminación, acoso e intimidación de cualquier tipo, respetando el espíritu y la intención de la Ley de Derechos Humanos.</p>
                    </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={12}>
                    <Box className={Styles.card}>
                        <h4>Responsabilidad Social</h4>
                        <p>En Nova nos interesa el bienestar de nuestras comunidades, el impacto positivo que causan construir puentes tecnológicos, nos proponemos ser una fuerza de cambio y mejoría, para nuestra sociedad en las cual operamos y en general un mundo más justo y sustentable.</p>
                    </Box>
                </Grid>
            </Grid>
        <Box mb="174px"></Box>
        <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <img src={gal1} alt="Reboot Academy" className={Styles.gallery} />
                    <img src={gal2} alt="Trabajo en equipo" className={Styles.gallery} />
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                    <img src={gal3} alt="Relajate en el comedor" className={Styles.gallery} />
                </Grid>
                <Grid item lg={5} md={5} sm={12}>
                    <img src={gal4} alt="Viernes social" className={Styles.gallery} />
                    <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <img src={gal5} alt="Relajate y toma un cafe" className={Styles.gallery} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <img src={gal6} alt="Amplias oficinas" className={Styles.gallery} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center">
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <img src={gal7} alt="Equipo Reboot" className={Styles.gallery} />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <img src={gal8} alt="Cursos internos" className={Styles.gallery} />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <img src={gal9} alt="Socialmente responsables" className={Styles.gallery} />
                </Grid>
            </Grid>
        </>
    )
}

export default Comprometemos
