import React from 'react'
import Heading from '../Heading/Heading';
import { Grid, Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Styles from './Principios.module.scss';
import customer from '../../assets/images/unete/customer.png';
import people from '../../assets/images/unete/people.png';
import team from '../../assets/images/unete/team.png';
import all from '../../assets/images/unete/all.png';

const Principios = () => {
    const matches = useMediaQuery('(min-width:960px)');
    return (
        <>
                <Grid container spacing={2} justifyContent="space-between">
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Heading
                            margin="66px"
                            subtitle="nuestros"
                            title="Principios operativos"
                            separator={ true }
                            description="Buscamos transmitir nuestros valores y creencias  en nuestros 4 principios operativos:"
                        />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box className={Styles.card}>
                            <img src={customer} alt="Customer centric" />
                            <h4>Customer  Centric</h4>
                            <p>Nos enfocamos en el consumidor que día a día usa  los productos  de nuestros clientes.</p>
                            <p m="0"><strong>“¡El éxito de nuestros clientes es nuestro éxito!”</strong></p>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box className={Styles.card}>
                            <img src={people} alt="People" />
                            <h4>People</h4>
                            <p><strong>El auténtico valor de nuestra empresa son los profesionales que formamos parte de ella, </strong>por lo que buscamos cada día mejorar nuestra calidad de vida a través de un equilibrio personal y profesional.</p>
                        </Box>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box mt={ matches && '-79px' }></Box>
                        <Box className={Styles.card} >
                            <img src={team} alt="Team for speed up" />
                            <h4>Team for speed up</h4>
                            <p><strong>Valoramos la fortaleza de trabajar en equipo y la diversidad </strong>de pensamiento, siendo transparentes y capaces de adaptarnos para lograr objetivos comunes.</p>
                        </Box>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <Box className={Styles.card}>
                            <img src={all} alt="All or nothing" />
                            <h4>All or nothing</h4>
                            <p><strong>Nos guía la pasión,</strong> centramos toda nuestra energía en lograr los retos y cumplir con los objetivos entregando resultados excepcionales. </p>
                        </Box>
                    </Grid>
                </Grid>
        </>
    )
}

export default Principios
