import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-TCGVJXQ',
    dataLayer: {
        userId: '001',
        userProject: 'project'
    }
}
 
TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById('root'))