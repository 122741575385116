import React from 'react'
import { Box, Grid } from '@material-ui/core'

import Styles from './HistoriaItem.module.scss';
import bullet from '../../assets/images/nosotros/bullet.svg';

const HistoriaItem = (props) => {
    return (
        <>
            <Box mt="-24px">
                <Grid container spacing={2}>
                    <Grid item lg={3} md={3} sm={3} xs={3} align="right">
                        <h5 className={Styles.year}>{props.year}</h5>
                    </Grid>
                    <Grid item lg={1} md={1} sm={1} xs={1} align="center" display="flex">
                        <img src={bullet} alt={props.alt} className={Styles.bullet} />
                        <span className={Styles.line}></span>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8}>
                        <h5 className={Styles.title}>{props.title}</h5>
                        <p className={Styles.text} dangerouslySetInnerHTML={{__html: props.text }}></p>
                    </Grid>
                </Grid>
            </Box>   
        </>
    )
}

export default HistoriaItem
