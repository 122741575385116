import React from 'react';
import Heading from '../Heading/Heading';
import { Grid } from '@material-ui/core';

import arquitectura from '../../assets/images/codi/arquitectura.png';

const Arquitectura = () => {
    return (
        <>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item lg={5} md={6} sm={12}>
                    <Heading
                        subtitle="SDK CoDi"
                        title="Arquitectura"
                        separator={ true }
                        description="El SDK esta diseñado con un paradigma reactivo para aprovechar las capacidades de los sistemas operativos móviles y poder ejecutar varios hilos en paralelo para hacer más eficiente el procesamiento de información. "
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item lg={9} md={12}>
                    <img src={arquitectura} alt="Arquitectura SDK CoDi" style={{display: 'block', width: '100%'}} />
                </Grid>
            </Grid>
        </>
    )
}

export default Arquitectura
