import React from 'react';
import { Container, Box } from '@material-ui/core';
import IntroText from '../IntroText/IntroText';
import nosotros from '../../assets/images/nosotros/nosotros.png';


const NotFound = () => {
    return (
        <>  <Box>
                <Container maxWidth="lg">
                <IntroText
                        marginTop="118px"
                        subtitle="La página que estás buscando no existe."
                        title="404"
                        normalParagraph="En Nova nuestra principal experiencia se basa en fusionar el conocimiento de trabajar con grandes instituciones y la agilidad que se obtiene en fintechs o empresas nativas digitales. "
                        boldParagraph="“Aceleramos la adopción digital en el sector financiero en México”."
                        image={ nosotros }
                        alt="Únete a Nova"
                    />
                <Box mt="600px"></Box>
                </Container>
            </Box>
        </>
    )
}

export default NotFound