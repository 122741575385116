import React from 'react'
import Heading from '../Heading/Heading';
import { Grid, Box } from '@material-ui/core';


import Styles from './Clientes.module.scss';
import scotia from '../../assets/images/home/scotia-logo.png';
import saba from '../../assets/images/home/saba-logo.png';
import independencia from '../../assets/images/home/independencia-logo.png';
import invex from '../../assets/images/home/invex-logo.png';
import bancoppel from '../../assets/images/home/bancoppel-logo.png';
import actinver from '../../assets/images/home/actinver-logo.png';
import multiva from '../../assets/images/home/multiva-logo.png';
import prosa from '../../assets/images/home/prosa-logo.png';

const Clientes = (props) => {
    return (
        <>
            <Box mb="278px">
                <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Heading
                            subtitle={ props.subtitle }
                            title={ props.title }
                            separator={ true }
                        />
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <div className={Styles.card}>
                            <img src={scotia} alt="Scotiabank" />
                        </div>
                    </Grid>
                </Grid>
                <Box mt={props.negativeMarginTop}>
                    <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <div className={Styles.card}>
                                <img src={saba} alt="Sabadell" />
                            </div>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <div className={Styles.card}>
                                <img src={bancoppel} alt="BanCoppel" />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <div className={Styles.card}>
                                <img src={invex} alt="Invex Banco" />
                            </div>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <div className={Styles.card}>
                                <img src={multiva} alt="Banco Multiva" />
                            </div>
                        </Grid>
                        <Grid item lg={4} md={4} sm={6} xs={12}>
                            <div className={Styles.card}>
                                <img src={actinver} alt="Actinver" />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justifyContent="center">
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <div className={Styles.card}>
                            <img src={independencia} alt="Financiera Independencia" />
                        </div>
                    </Grid>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                        <div className={Styles.card}>
                            <img src={prosa} alt="Prosa" />
                        </div>
                    </Grid>
                </Grid>
                </Box>
            </Box>
        </>
    )
}

export default Clientes
