import React from 'react';
import Heading from '../Heading/Heading';
import { Grid, Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Styles from './Funcionalidades.module.scss';
import sdk from '../../assets/images/codi/qr.svg';

const Funcionalidades = () => {
    const matches = useMediaQuery('(min-width:960px)');

    return (
        <>
            <Box mt="80px"></Box>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item lg={5} md={6} sm={12}>
                    <Heading
                        subtitle="SDK CoDI "
                        title="Funcionalidades"
                        separator={ true }
                        description="Pueden resolver las necesidades de una persona. Un ejemplo de las actividades que puede realizar nuestro Chatbot mediante una conversación automatizada , sencilla y coloquial son: "
                    />
                    <img src={sdk} alt="SDK CoDi" />
                </Grid>
                <Grid item lg={5} md={6} sm={12}>
                    <Box mt={ matches && '139px'}></Box>
                    <Box className={Styles.card} >
                        <h4>SDK CoDi</h4>
                        <ul>
                            <li>Registro inicial</li>
                            <li>Registro subsecuente</li>
                            <li>Registro por omisión</li>
                            <li>Catalogo de institucioneso</li>
                            <li>Validaciones de cuenta</li>
                            <li>Consulta de validación de cuenta</li>
                            <li>Solicitud de cobro</li>
                            <li>Consulta de estado de operación</li>
                            <li>Solicitud de clave de cifrado</li>
                            <li>Consulta de mensajes de pago</li>
                            <li>Baja de dispositivos</li>
                        </ul>
                    </Box>
                </Grid>
            </Grid>   
        </>
    )
}

export default Funcionalidades
