import React, {useEffect, useRef, useState} from 'react';
import Heading from '../Heading/Heading';
import IntItem from './IntItem';
import {Box} from '@material-ui/core';

import Styles from './Integracion.module.scss';
import integracion from '../../assets/images/rao/integracion.png';
import formularios from '../../assets/images/rao/formularios.png';
import tipos from '../../assets/images/rao/tipos.png';
import validacion from '../../assets/images/rao/validacion.png';
import registro from '../../assets/images/rao/registro.png';
import entrevista from '../../assets/images/rao/entrevista.png';
import conectividad from '../../assets/images/rao/conectividad.png';
import downselling from '../../assets/images/rao/downselling.png';
import actualizacion from '../../assets/images/rao/actualizacion.png';

const Integracion = () => {
    
    const refMin = useRef();
    const refMax = useRef();
    const [scroll, setScroll] = useState(false);

    /*function handleScroll() {
        if(window.scrollY > refMin.current.offsetTop && window.scrollY < refMax.current.offsetTop -300){
            setScroll(true);
        }else{
            setScroll(false);
        }
    }
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])*/

    return (
        <>
            <Box ref={refMin}></Box>
            <Box style={{height: '130px'}}>
                <Box className={ scroll ? Styles.fixed : undefined }>
                    <Heading
                        subtitle="Nova RAO"
                        title="Integración"
                        separator={ true }
                    />
                </Box>
            </Box>
            <IntItem
                content="<ul><li>Apertura de cuenta en el core del banco</li><li>Ambiente de  pruebas y conexión del banco</li><li>APIsTiempo real</li><li>Conexión VPN si está en Cloud </li><li>Enrolamiento en línea a banca digital </li><li>Integración/Asignación  de SoftToken </li><li>2do factor de autenticación</li><li>Storage  bucket </li></ul>"
                image={ integracion }
                alt="Integración"
            />
            <IntItem
                content="<h4>Formularos preaprobados</h4><p>Nova RAO ofrece secciones o sub formularios de entrada de una solicitud de producto bancario, con los campos y validaciones estándares de la industria, en el orden y diseño probado, para maximizar la probabilidad de llenado y por ende de conversión de los mismos. </p>"
                image={ formularios }
                alt="Formularos preaprobados"
            />
            <IntItem
                content="<h4>Tipos de cuenta</h4><p>Pre configuradas dependiendo de los requerimientos de la institución y el regulador, permitiendo originar cuentas de los siguientos tipos sin necesidad de modificaciones:</p><ul><li>N1</li><li>N2</li><li>N4</li><li>Tarjeta de Crédito</li></ul>"
                image={ tipos }
                alt="Tipos de cuenta"
            />
            <IntItem
                content="<h4>Validación INE/IFE</h4><p>Nova RAO ofrece servicios avanzados de reconocimiento de imágenes, para la rectificación y mejora de calidad de los documentos capturados por la cámara del celular, manteniendo las imágenes originales y mejoradas para el respaldo regulatorio. </p>"
                image={ validacion }
                alt="Validación INE/IFE"
            />
            <IntItem
                content="<h4>Registro Facial</h4><p>Nova RAO contiene las funcionalidades de captura de fotografía/video de los rasgos faciales, los cuales puede utilizar para comparar la captura con el rostro presente en el INE y alertar en caso de que el porcentaje de similitud sea más bajo al esperado, permitiendo intervención de un operador humano para la revisión posterior del expediente del cliente.</p>"
                image={ registro }
                alt="Registro Facial"
            />
            <IntItem
                content="<h4>Entrevista remota</h4><p>Nova RAO incluye la funcionalidad de video entrevista, requerida para abrir cuentas N4 o con niveles de autorización/riesgo superior. </p>"
                image={ entrevista }
                alt="Entrevista remota"
            />
            <IntItem
                content="<h4>Conectividad a Autorizadores de Productos</h4><p>Nova RAO incluye conectividad vía una cola Kafka, a entidades de historial crediticio como el buró de crédito, y mediante desarrollo vía APIs puede conectarse a los autorizadores internos de la institución, para realizar la aprobación y el aprovisionamiento de nuevos productos de forma automática.</p>"
                image={ conectividad }
                alt="Conectividad a Autorizadores de Productos"
            />
            <IntItem
                content="<h4>Downselling o BAP (Best Approved Product)</h4><p>Nova RAO incluye funcionalidad para contraofertar al cliente, productos preaprobados de un rango menor, en caso de que no haya sido aprobado por la institución el producto en el que el cliente estaba interesado. </p>"
                image={ downselling }
                alt="Downselling o BAP (Best Approved Product)"
            />  
            <IntItem
                content="<h4>Actualización de Expediente del Cliente</h4><p>Nova RAO mediante formularios personalizados permite a la institución enviar ligas cortas con datos precapturados de los clientes, sólo para validación de información en el expediente del cliente, y mediante autenticación (puede ser con celular certificado), permitir la actualización de documentos expirados, o con antigüedad mayor a la requerida por la institución por sus políticas internas o regulatorias.</p>"
                image={ actualizacion }
                alt="Actualización de Expediente del Cliente"
            />
             <Box ref={refMax}></Box>
        </>
    )
}

export default Integracion
