import React from 'react';
import Heading from '../Heading/Heading';
import { Grid, Box, Button } from '@material-ui/core';

import Styles from './Buscamos.module.scss';
import android from '../../assets/images/unete/android.svg';
import ios from '../../assets/images/unete/ios.svg';
import frontend from '../../assets/images/unete/front.svg';
import java from '../../assets/images/unete/java.svg';
import arquitecto from '../../assets/images/unete/man1.svg';
import lead from '../../assets/images/unete/woman1.svg';
import business from '../../assets/images/unete/woman2.svg';
import tech from '../../assets/images/unete/man2.svg';

const Buscamos = () => {
    return (
        <>
                <Heading
                    subtitle="Únete"
                    title="Buscamos profesionales"
                    separator={ true }
                />
                <Box mt="80px"></Box>
                <Grid container spacing={2}>
                    <Grid item lg={3} md={3} sm={4} xs={6} align="center">
                        <img src={android} alt="Android Developer" className={ Styles.image } />
                        <p className={ Styles.name }>Android Developer</p>
                    </Grid>
                    <Grid item lg={3} md={3} sm={4} xs={6} align="center">
                        <img src={ios} alt="iOS Developer" className={ Styles.image } />
                        <p className={ Styles.name }>iOS Developer</p>
                    </Grid>
                    <Grid item lg={3} md={3} sm={4} xs={6} align="center">
                        <img src={frontend} alt="Frontend Developer" className={ Styles.image } />
                        <p className={ Styles.name }>Frontend Developer</p>
                    </Grid>
                    <Grid item lg={3} md={3} sm={4} xs={6} align="center">
                        <img src={java} alt="Backend Java Developer" className={ Styles.image } />
                        <p className={ Styles.name }>Backend Java Developer</p>
                    </Grid>
                    <Grid item lg={3}  md={3} sm={4} xs={6} align="center">
                        <img src={arquitecto} alt="Arquitecto de Micro Servicios" className={ Styles.image } />
                        <p className={Styles.name }>Arquitecto de Micro Servicios</p>
                    </Grid>
                    <Grid item lg={3}  md={3} sm={4} xs={6} align="center">
                        <img src={lead} alt="Technical Lead Java" className={ Styles.image } />
                        <p className={ Styles.name }>Technical Lead Java</p>
                    </Grid>
                    <Grid item lg={3}  md={3} sm={4} xs={6} align="center">
                        <img src={business} alt="Business Analyst" className={ Styles.image } />
                        <p className={ Styles.name }>Business Analyst</p>
                    </Grid>
                    <Grid item lg={3}  md={3} sm={4} xs={6} align="center">
                        <img src={tech} alt="Tech Analyst" className={ Styles.image } />
                        <p className={ Styles.name }>Tech Analyst</p>
                    </Grid>
                </Grid>
                <Box mt="40px"></Box>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item lg={2} md={3} sm={8} xs={10}>
                        <Button
                            href="mailto:contacto@novasolutionsystems.com?subject=Envio de CV&body=Que tal, hago el envío de mi CV para que se me tome en cuenta en una de las posibles vacantes dentro de la empresa"
                            className="button-md" 
                            variant="contained" 
                            disableElevation 
                            fullWidth 
                            color="primary">Enviar mi CV</Button>
                    </Grid>
                </Grid> 
        </>
    )
}

export default Buscamos
