import React from 'react';
import IntroText from '../IntroText/IntroText';
import Caracteristicas from './Caracteristicas';
import Solucion from './Solucion';
import Integracion from './Integracion';
import { Box, Container } from '@material-ui/core';

import background from '../../assets/images/rao/bg.svg';
import rao from '../../assets/images/rao/rao.png';

const Rao = () => {

    return (
        <>
            <Box pt="118px" style={{
                backgroundImage: 'url('+ background +')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% auto',
                backgroundPosition: 'left 639px' 
            }}>
                <Container maxWidth="lg">
                    <IntroText
                        marginTop="104px"
                        subtitle="Adquisición digital de clientes"
                        title="Nova RAO"
                        normalParagraph="<strong>Le permite a las instituciones financieras la agilidad para crear procesos de adquisición dinámicos, a una velocidad al mercado superior.</strong>"
                        boldParagraph="“Adquisición, onboarding y originación  de clientes”"
                        image={ rao }
                        alt="Nova RAO más clientes"
                    />
                    <Box mt="254px"></Box>
                    <Caracteristicas />
                    <Box mt="208px"></Box>
                    <Solucion />
                    <Box mt="350px"></Box>
                    <Integracion />
                    <Box mt="350px"></Box>
                </Container>
                
            </Box>
        </>
    )
}

export default Rao