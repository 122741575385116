import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import { Button, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Styles from './Heading.module.scss';

const Heading = (props) => {
    const matches = useMediaQuery('(min-width:960px)');
    return (
        <>
            <h4 className={ matches ? Styles.subtitle : Styles.subtitleSmall} style={{ marginTop: props.margin }}>
                 { props.subtitle }
            </h4>
            <Grid item lg={10} md={12} sm={12}> 
                <h2 className={ matches ? Styles.title : Styles.titleSmall}>
                    { props.title }
                </h2>
            </Grid>
            { props.separator &&
                <div className={Styles.separator}></div>
            }
            <p className={ props.bold ? Styles.boldDescription : Styles.normalDescription}>
                { props.description }
            </p>
            { props.button &&
                <Button component={Link} to={props.to} color="primary" variant="contained" className="button-md" disableElevation>
                    { props.button }
                </Button>
            }
        </>
    )
}

export default Heading
