import React from 'react';
import Heading from '../Heading/Heading';
import {Box, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Styles from './Caracteristicas.module.scss';
import beneficios from '../../assets/images/chatbots/beneficios.svg';
import mensajeria from '../../assets/images/chatbots/mensajeria.svg';
import relacion from '../../assets/images/chatbots/relacion.svg';
import tecnologia from '../../assets/images/rao/integracion.svg';

const Caracteristicas = () => {
    const matches = useMediaQuery('(min-width:960px)');

    return (
        <>
            <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12}>
                    <Grid container spacing={2}>
                        <Grid item lg={10} md={10} sm={12}>
                            <Heading
                                subtitle="beneficios y"
                                title="Características"
                                description="Nuestro  Chatbot ha sido entrenado principalmente para llevar a cabo las funciones de atención al cliente del sector financiero."
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={12}>
                            <Box mt={ matches && '165px' }></Box>
                            <Box className={Styles.card}>
                                <img src={mensajeria} alt="Aplicaciones de mensajería" />
                                <h4>Aplicaciones de mensajería</h4>
                                <p>Integrados con los asistentes virtuales del mercado como (Google Assistant, Siri, Amazon Alexa, etc)</p>
                            </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12}>
                        <Box mt={ matches && '65px' }></Box>
                            <Box className={Styles.card}>
                                <img src={relacion} alt="Mejora de relación" />
                                <h4>Mejora de relación</h4>
                                <p>Entre cliente y las entidades financieras al incluir una rica interfaz grafica la cual permite responder a los usuarios con los denominados 'mensajes estructurados'</p>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={3} md={3} sm={12}>
                <Box mt={ matches && '262px' }></Box>
                    <Box className={Styles.card} >
                        <img src={tecnologia} alt="Tecnología de Google" />
                        <h4>Tecnología de Google</h4>
                        <p>Dialogflow  compañía que ha  apostando en la creación de estos softwares basados en IA y que ha mostrado ser una tecnología robusta. </p>
                    </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={12}>
                <Box mt={ matches && '37px' }></Box>
                    <Box className={Styles.card} >
                        <img src={beneficios} alt="Beneficios económicos" />
                        <h4>Beneficios económicos</h4>
                        <p>Al generar ingresos a través de las suscripciones, la publicidad, el ahorro de costos en Centros de Atención, la mejora en la calidad de servicio y una grata experiencia de usuario.</p>
                    </Box>
                </Grid>
            </Grid>

        </>
    )
}

export default Caracteristicas
