import React from 'react';
import Heading from '../Heading/Heading';
import { Grid, Box } from '@material-ui/core';

import Styles from './BestPlaceToCode.module.scss';
import best from '../../assets/images/unete/best.png';

const BestPlaceToCode = () => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12}>
                    <img src={ best } alt="Best Place to Code" className={Styles.image} />
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                    <Heading 
                        title="Best Place to Code"
                        separator={ true }
                        description="El mejor lugar para codificar es un programa de Software Guru para identificar y promover empresas que se esfuerzan por brindar las mejores condiciones de trabajo a los desarrolladores de software."
                    />
                    <Box mb="83px"></Box>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item lg={4} md={5} sm={4} xs={6}>
                            <Box className={Styles.card} display="flex" flexDirection="column" justifyContent="center">
                                <h4>100<span>%</span></h4>
                                <p><strong>Compensación</strong></p>
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={5} sm={4} xs={6}>
                            <Box className={Styles.card} display="flex" flexDirection="column" justifyContent="center">
                                <h4>100<span>%</span></h4>
                                <p><strong>Desarrollo profesional</strong></p>
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={5} sm={4} xs={6}>
                            <Box className={Styles.card} display="flex" flexDirection="column" justifyContent="center">
                                <h4>100<span>%</span></h4>
                                <p><strong>Cultura</strong></p>
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={5} sm={4} xs={6}>
                            <Box className={Styles.card} display="flex" flexDirection="column" justifyContent="center">
                                <h4>92<span>%</span></h4>
                                <p><strong>Gender Friendly</strong></p>
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={5} sm={4} xs={6}>
                            <Box className={Styles.card} display="flex" flexDirection="column" justifyContent="center">
                                <h4>100<span>%</span></h4>
                                <p><strong>Infraestructura</strong></p>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>  
        </>
    )
}

export default BestPlaceToCode
