import React from 'react';
import { Grid, Box } from '@material-ui/core';
import Heading from '../Heading/Heading';

import Styles from './Partners.module.scss';
import tesobe from '../../assets/images/openbanking/tesobe-logo.png';
import openbank from '../../assets/images/openbanking/openbank-logo.png';
import citi from '../../assets/images/openbanking/citi-logo.png';
import citizens from '../../assets/images/openbanking/citizens-logo.png';
import payments from '../../assets/images/openbanking/payments-logo.png';
import praxia from '../../assets/images/openbanking/praxia-logo.png';
import abn from '../../assets/images/openbanking/abn-logo.png';
import cuscal  from '../../assets/images/openbanking/cuscal-logo.png';
import dai from '../../assets/images/openbanking/dai-logo.png';
import adcb from '../../assets/images/openbanking/adcb-logo.png';
import postale from '../../assets/images/openbanking/postale-logo.png';
import bnp from '../../assets/images/openbanking/bnp-logo.png';
import santander from '../../assets/images/openbanking/santander-logo.png';
import hsbc from '../../assets/images/openbanking/hsbc-logo.png';
import tab from '../../assets/images/openbanking/tab-logo.png';
import finca from '../../assets/images/openbanking/finca-logo.png';
import galaxy from '../../assets/images/openbanking/galaxy-logo.png';
import ttk from '../../assets/images/openbanking/ttk-logo.png';
import ifc from '../../assets/images/openbanking/ifc-logo.png';
import ahli from '../../assets/images/openbanking/ahli-logo.png';

const Partners = () => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item lg={7} md={7} sm={12}>
                    <Heading
                        subtitle="Partner y Representante"
                        title="TESOBE & NOVA"
                        separator={ true }
                    />
                    <Grid container spacing={2}>
                        <Grid item lg={8} md={8} sm={12}>
                            <ul className={Styles.list}>
                                <li>Nova es partner Gold de TESOBE</li>
                                <li> Representante de TESOBE en Latinoamerica Capacitado para  implementar las solución OBP</li>
                            </ul> 
                        </Grid>
                    </Grid>
                    <Box className={Styles.card} mt="56px">
                        <img src={tesobe} alt="Tesobe" />
                        <ul>
                            <li>Consultoría y negocio de ingeniería FinTech con base en Berlín y el Reino Unido</li>
                            <li>Comenzó el Proyecto Banco Abierto en 2010</li>
                            <li>Inspirado por la Banca Abierta del Reino Unido, el Grupo de Berlín, el CDR de Australia, & más</li>
                        </ul>
                        <img src={openbank} alt="Open Bank Project" />
                        <ul>
                            <li>Liderando la solución de banca abierta</li>
                            <li>Catálogo estándar con más de 350 APIs</li>
                            <li>Vibrante comunidad mundial de desarrolladores y asociados 11k+ desarrolladores</li>
                            <li>Base de clientes de nivel 1, 2 y 3 a nivel mundial</li>
                        </ul>
                    </Box>
                </Grid>
                <Grid item lg={5} md={5} sm={12}>
                        <Box display="flex" flexWrap="wrap" className={Styles.imagelist}>
                        <img src={citi} alt="Citibanamex" />
                        <img src={bnp} alt="BNP Paribas" />
                        <img src={citizens} alt="Citizens Bank" />
                        <img src={santander} alt="Santander" />
                        <img src={payments} alt="Australian Payments Network" />
                        <img src={hsbc} alt="HSBC" />
                        <img src={praxia} alt="Praxia Bank" />
                        <img src={tab} alt="TAB Bank" />
                        <img src={abn} alt="ABN AMRO" />
                        <img src={finca} alt="Finca Bank" />
                        <img src={cuscal} alt="Cuscal The Complete Payments Partner" />
                        <img src={galaxy} alt="Fintech Galaxy" />
                        <img src={dai} alt="DAI Shaping a more liveable world" />
                        <img src={ttk} alt="TTK Gahka" />
                        <img src={adcb} alt="ADCB Long Live Ambition" />
                        <img src={ifc} alt="IFC International Financial Corporation" />
                        <img src={postale} alt="La Banque Postale" />
                        <img src={ahli} alt="Ahli" />
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Partners
