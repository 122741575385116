import React from 'react'
import Heading from '../Heading/Heading';
import { Grid, Box} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Styles from './Porque.module.scss';

const Porque = () => {
    const matches = useMediaQuery('(min-width:1280px)');

    return (
        <>
            <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Heading
                        subtitle="Tenemos toda la experiencia"
                        title="¿Por qué nosotros?"
                        separator={ true }
                    />
                </Grid>
                <Grid item lg={4} md={5} sm={5} xs={12}>
                    <Box className={Styles.card}>
                        <h4>Somos una consultora 100% digital</h4>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={ matches ? 6 : 2}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box mt={ matches && '96px'}></Box>
                    <Box className={Styles.card} >
                        <h4>Tenemos la capacidad de responder rápidamente a sus requerimientos</h4>
                    </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box className={Styles.card}>
                        <h4>Sólo atendemos al Sector Financiero</h4>
                        <p><strong>Lo que nos lleva a ofrecer un mayor grado de especialización</strong> en prácticas adecuadas para entender las condiciones de los productos digitales como es la regulación, la optimización por tiempos de respuesta, agilidad en procesos, etc.</p>
                    </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box className={Styles.card}>
                        <h4>Experiencia en todas las funcionalidades</h4>
                        <p>Tenemos experiencia en todas las funcionalidades <strong>requeridas por la banca electrónica y originación digital</strong> del mercado mexicano.</p>
                    </Box>
                </Grid>
            </Grid>
            <Box mb="249px"></Box>
        </>
    )
}

export default Porque
