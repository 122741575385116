import React from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { Button, Container, Grid, Box} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { ReactComponent as Logo } from '../../assets/images/logo-white.svg';
import { ReactComponent as IcoLocation } from '../../assets/images/ico-location.svg';
import { ReactComponent as IcoMobile } from '../../assets/images/icon-mobile.svg';
import { ReactComponent as IcoEmail } from '../../assets/images/ico-mail.svg';

import facebookIcon from '../../assets/images/social_networks/facebook-icon.svg';
import twitterIcon from '../../assets/images/social_networks/twitter-icon.svg';
import linkedinIcon from '../../assets/images/social_networks/linkedin-icon.svg';

import Styles from './Footer.module.scss';

const Footer = () => {
    const location = useLocation();
    const matches = useMediaQuery('(min-width:1280px)');

    return(
        <>
        { location.pathname !== '/contactanos' && 
            <Box className={ !matches ? Styles.footer : undefined}>
            <Container maxWidth="lg">
                <Box pb="80px">
                    <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={12} xs={12} className={Styles.logoBlock}>
                        <Logo title="Nova a Reboot Company" className={Styles.logo} />
                        <ul className={Styles.ulIcon}>
                            <li>
                                <a href="https://www.facebook.com/novasolutionsystems" target="_blank" rel="noreferrer"><img className={Styles.icons} src={facebookIcon} alt="Nova Facebook"/></a>
                                <a href="https://twitter.com/novasolutionsys" target="_blank" rel="noreferrer"><img className={Styles.icons} src={twitterIcon} alt="Nova Twitter"/></a>
                                <a href="https://www.linkedin.com/company/novasolutionsystems" target="_blank" rel="noreferrer"><img className={Styles.icons} src={linkedinIcon} alt="Nova LinkedIn"/></a>
                            </li>
                        </ul>
                        <h3>Contacto</h3>
                        <Box display="flex" alignItems="flex-start">
                            <IcoMobile title="Teléfono" />
                            <p><small>
                                +52 (55) 4172 8497
                            </small></p>
                        </Box>
                        <Box display="flex" alignItems="flex-start">
                            <IcoLocation title="Dirección" />
                            <p><small>
                                Torre Axxa , Xola 535, piso 29, Col del Valle Nte,<br />
                                Benito Juárez, 03103 Ciudad de México, CDMX
                            </small></p>
                        </Box>
                        <Box display="flex" alignItems="flex-start">
                            <IcoEmail title="Correo electrónico" />
                             <p><small>
                                <a
                                    href="mailto:contacto@novasolutionsystems.com?subject=Solicitud de información&body=Que tal, Visité su sitio web y me gustaría conocer más sobre sus servicios."
                                    title="Correo electrónico"
                                >contacto@novasolutionsystems.com</a>
                            </small></p>
                        </Box>
                    </Grid>
                    <Grid item lg={2} md={2} sm={4} xs={6}>
                        <ul className={Styles.whiteBlock}>
                            <li><h3>Soluciones</h3></li>
                            <li><Link to="/open-banking#">Open Banking</Link></li>
                            <li><Link to="/nd-bank#">ND Bank</Link></li>
                            <li><Link to="/rao#">RAO</Link></li>
                            <li><Link to="/sdk-codi#">SDK CoDi</Link></li>
                            <li><Link to="/chatbots#">Chatbots</Link></li>
                        </ul>
                    </Grid>
                    <Grid item lg={3} md={3} sm={4} xs={6}>
                        <ul className={Styles.whiteBlock}>
                            <li><h3>Nosotros</h3></li>
                            <li><Link to="/nosotros#hacemos">¿Qué hacemos?</Link></li>
                            <li><Link to="/soluciones#soluciones">Nuestras Soluciones</Link></li>
                            <li><Link to="/#agiles">Profundamente Ágiles</Link></li>
                            <li><Link to="/nosotros#historia">Nuestra Historia</Link></li>
                            <li><Link to="/nosotros#alianzas">Nuestras Alianzas</Link></li>
                        </ul>
                    </Grid>
                    <Grid item lg={3} md={3} sm={4} xs={12} className={matches ? Styles.whiteBG : Styles.blackBG}>
                        <h3>Únete a Nova</h3>
                        <p>Gracias al profesionalismo de nuestros consultores, somos una empresa Best Place to Code.</p>
                        <p>Aquí tendrás crecimiento laboral como social, únete y vive la evolución digital.</p>
                        <Grid container spacing={2}>
                            <Grid item lg={8} md={8} sm={6} xs={6}>
                            <Button component={Link} to="/unete#Buscamos-profesionales" className="button-sm" variant="contained" disableElevation fullWidth color="primary">Ver vacantes</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                </Box>
            </Container>
            </Box>
        }
        </>
    )
}

export default Footer;