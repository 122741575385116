import React from 'react';
import IntroText from '../IntroText/IntroText';
import SolucionesItem from './SolucionesItem';
import { Container, Box, Button } from '@material-ui/core';

import soluciones from '../../assets/images/soluciones/soluciones.png';
import openBanking from '../../assets/images/soluciones/open-banking.png';
import ndBank from '../../assets/images/soluciones/nd-bank.png';
import rao from '../../assets/images/soluciones/rao.png';
import codi from '../../assets/images/soluciones/codi.png';
import chatbots from '../../assets/images/soluciones/chatbots.png';
import bgSoluciones from '../../assets/images/soluciones/background.svg';
import icon from '../../assets/images/home/icon-soluciones.svg';
import open from '../../assets/images/ndbank/integracion.svg';
import qr from '../../assets/images/codi/qr.svg';
import cloud from '../../assets/images/ndbank/cloudnative.svg';
import chat from '../../assets/images/chatbots/conversacionales.svg';
import rebootNova from '../../assets/pdf/WHITEPAPER. LA QUINTA EVOLUCIOìN DE LA BANCA DIGITAL EN MEìXICO NOVA..pdf'

const Soluciones = () => {
    return (
        <>
            <Box id="soluciones" style={{
                backgroundImage: 'url('+ bgSoluciones +')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% auto',
                backgroundPosition: 'left 448px'
            }}>
                <Container maxWidth="lg">
                <IntroText
                    marginTop={'104px'}
                    subtitle="Somos expertos"
                    title="Soluciones"
                    normalParagraph="El estar enfocados en el sector financiero, nos lleva a tener un mayor grado de especialización en las funcionalidades requeridas por la banca digital del mercado Mexicano permitiéndonos crear soluciones que atiendan el reto de generar experiencias de valor a los usuarios finales."
                    boldParagraph="“Aceleramos la adopción digital en el sector financiero mexicano”"
                    image={ soluciones }
                    alt="Aceleramos la adopción digital en el sector financiero mexicano"
                />
                <Button variant="contained" color="primary" className="button-md" href={rebootNova} target='_blank' disableElevation>Descargar Whitepaper Banca Digital</Button>
                <Box mb="364px"></Box>
                <SolucionesItem
                    paddingTop="115px"
                    paddingBottom="85px"
                    bg={ openBanking }
                    bgSize="75% 100%"
                    bgPosition="right top"
                    right={ false }
                    icon={ open }
                    iconAlt="Open Banking"
                    title="Open Banking"
                    description="Es una solución líder en el mercado que permite atender las normatividad en México así como promueve la integración al ecosistema de Banca Abierta en México y el mundo. <br /><br /> 1 Time to market <br /> 2 Cumplimiento regulatorio <br /> 3 Mantenerse al día con BaaS y BaaP"
                    buttonText="Ver más"
                    buttonTo="/open-banking#"
                />
                <SolucionesItem
                    paddingTop="91px"
                    paddingBottom="109px"
                    bg={ ndBank }
                    bgSize="75% 100%"
                    bgPosition="lef top"
                    right={ true }
                    icon={ icon }
                    iconAlt="ND Bank"
                    title="ND Bank"
                    description="Es nuestro framework de banca digital web y móvil para banca de consumo y empresarial. <br /><br /> Una solución basada en componentes preconstruidos (widgets) , administrables y configurables por la institución financiera, cumpliendo con los temas regulatorios mexicanos."
                    buttonText="Ver más"
                    buttonTo="/nd-bank#"
                    />
                <SolucionesItem
                    paddingTop="41px"
                    paddingBottom="109px"
                    bg={ rao }
                    bgSize="55% 100%"
                    bgPosition="right top"
                    right={ false }
                    icon={ cloud }
                    iconAlt="RAO"
                    title="RAO"
                    description="Le permite a las instituciones financieras la agilidad para crear procesos de adquisición dinámicos que permiten una velocidad al mercado superior, cumpliendo todos los requerimientos de la regulación mexicana."
                    buttonText="Ver más"
                    buttonTo="/rao#"
                />
                <SolucionesItem
                    paddingTop="91px"
                    paddingBottom="109px"
                    bg={ codi }
                    bgSize="75% 100%"
                    bgPosition="lef top"
                    right={ true }
                    icon={ qr }
                    iconAlt="SDK CoDi"
                    title="SDK CoDi"
                    description="Un componente de software aislado que se puede integrar fácilmente con cualquier aplicación móvil para realizar las comunicaciones entre un app móvil y las APIs de Banxico"
                    buttonText="Ver más"
                    buttonTo="/sdk-codi#"
                />
                <SolucionesItem
                    paddingTop="91px"
                    paddingBottom="109px"
                    bg={ chatbots }
                    bgSize="75% 100%"
                    bgPosition="right top"
                    right={ false }
                    icon={ chat }
                    iconAlt="Chatbots"
                    title="Chatbots"
                    description="Chatbot para redes sociales y páginas web, permite integrarse con los sistemas back del banco para realizar transacciones y consultas bancarias, reconocimiento de identificaciones y recibos,  resolver preguntas frecuentes, conversión de monedas entre otras funcionalidades.  "
                    buttonText="Ver más"
                    buttonTo="/chatbots#"
                />
            </Container>
            </Box>
        </>
    )
}

export default Soluciones