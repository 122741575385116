import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import { Grid, Box, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Styles from './SolucionesItem.module.scss';

const SolucionesItem = (props) => {
    const matches = useMediaQuery('(min-width:960px)');
    return (
        <>
            <Box pt={ matches ? props.paddingTop : '0px'} pb={ matches ? props.paddingBottom : '0px'} mb="282px" style={{ 
                backgroundImage: matches ? 'url(' + props.bg + ')' : undefined,
                backgroundRepeat: 'no-repeat',
                backgroundSize: props.bgSize,
                backgroundPosition: props.bgPosition
                 }}>
                <Grid container spacing={2}>
                    {
                        props.right && <Grid item lg={6} md={4}></Grid>
                    }
                    <Grid item lg={6} md={8} sm={12} xs={12}> 
                        <Box className={Styles.card}>
                            <img src={ props.icon } alt={ props.iconAlt } className={ props.right ? Styles.iconRight : Styles.iconLeft } />
                            <h3 className={Styles.title}>{ props.title }</h3>
                            <p className={Styles.description} dangerouslySetInnerHTML={{__html: props.description }}></p>
                            <Grid container spacing={2}>
                                <Grid item lg={4} md={6} sm={6} xs={6}>
                                    <Button component={Link} to={props.buttonTo} 
                                        className="button-md" 
                                        variant="contained" 
                                        disableElevation
                                        fullWidth
                                        color="primary">{ props.buttonText }
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>   
        </>
    )
}

export default SolucionesItem
