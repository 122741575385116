import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Header, Footer, Home, Soluciones, Nosotros, Unete, Contactanos, Openbanking, Ndbank, Rao, Codi, Chatbots, Seo, NotFound } from './components';

import './css/App.scss';
import backgroundBottom from './assets/images/bg-bottom.svg';
import backgroundTop from './assets/images/bg-top.svg';
import { createTheme, ThemeProvider } from '@material-ui/core';

const theme = createTheme({
    palette: {
      primary: {
        light: '#5AADFF',
        main: '#3399FF',
        dark: '#0381FF',
        contrastText: '#fff',
      }
    }
  });

const App = () => {

    return (
        <ThemeProvider theme={theme}>
            <Router basename="/">
            <main
            style={{ 
                backgroundImage:  `url(${backgroundBottom}), url(${backgroundTop})`, 
                backgroundPosition: 'left bottom, right top', 
                backgroundRepeat: 'no-repeat, no-repeat', 
                backgroundSize: '66% auto, 68% auto' }}>
                <Header />
                <Seo />
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                        <Route exact path="/soluciones">
                            <Soluciones />
                        </Route>
                        <Route exact path="/nosotros">
                            <Nosotros />
                        </Route>
                        <Route exact path="/unete">
                            <Unete />
                        </Route>
                        <Route exact path="/contactanos">
                            <Contactanos />
                        </Route>
                        <Route exact path="/open-banking">
                            <Openbanking />
                        </Route>
                        <Route exact path="/nd-bank">
                            <Ndbank />
                        </Route>
                        <Route exact path="/rao">
                            <Rao />
                        </Route>
                        <Route exact path="/sdk-codi">
                            <Codi />
                        </Route>
                        <Route exact path="/chatbots">
                            <Chatbots />
                        </Route>
                        <Route component={NotFound} />
                    </Switch>
                <Footer />
            </main>
            </Router>
        </ThemeProvider>
    )
}

export default App;