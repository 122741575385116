import React from 'react'
import Heading from '../Heading/Heading';
import { Grid, Box } from '@material-ui/core';

import Styles from './Agiles.module.scss';
import scrum from '../../assets/images/home/scrum-logo.png';
import istqb from '../../assets/images/home/istqb-logo.png';
import safe from '../../assets/images/home/safe-logo.png';
import agiles from '../../assets/images/home/agiles.png';

const Agiles = () => {
    return (
        <Box mt="220px" id="agiles">
            <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12}>
                    <img src={agiles} alt="Profundamente Ágiles" style={{width:'100%'}} />
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                    <Grid container spacing={2}>
                        <Grid item lg={10} md={10} sm={12}>
                            <Heading
                                title="Profundamente Ágiles"
                                description="Somos los aceleradores para las áreas de negocio e IT a la era digital."
                                bold={ false }
                                separator={ true }
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item lg={4} md={4} sm={4} xs={6}>
                            <div className={Styles.card}>
                                <h4>+250</h4>
                                <p>Profesionales dedicados a la entrega</p>
                            </div>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={6}>
                            <div className={Styles.card}>
                                <img src={scrum} alt="CSM Certified" className={Styles.logo} />
                                <h4>+20</h4>
                                <p>SCRUM Masters certificados</p>
                            </div>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={6}>
                            <div className={Styles.card}>
                                <h4>+20</h4>
                                <p>Equipos  SCRUM en ejecución</p>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item lg={4} md={4} sm={4} xs={6}>
                            <div className={Styles.card}>
                                <img src={istqb} alt="Tester certificados" className={Styles.logo} />
                                <h4>+35</h4>
                                <p>Tester Certificados</p>
                            </div>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={6}>
                            <div className={Styles.card}>
                                <img src={safe} alt="SAFe certified" className={Styles.logo} />
                                <h4>+6</h4>
                                <p>SAFE Agilistas certificados</p>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Agiles
