import React from 'react';
import { Grid, Box } from '@material-ui/core';
import Heading from '../Heading/Heading';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Styles from './Trayectoria.module.scss';

const Trayectoria = () => {
    const matches = useMediaQuery('(min-width:960px)');

    return (
        <>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item lg={5} md={6} sm={12}>
                    <Heading
                        subtitle="Trayectoria"
                        title="Open Banking"
                        separator={ true }
                        description="Las más grandes instituciones financieras trabajan con nosotros."
                    />
                    <Box mt="56px" ></Box>
                    <Box className={Styles.text}>
                        <p>Open Banking es un movimiento mundial que alienta a las instituciones financieras a permitir el acceso de terceros autorizados:</p>
                        <ol>
                            <li>a) A los datos de los clientes individuales y empresariales</li>
                            <li>b) A los servicios bancarios (como la iniciación del pago)</li>
                            <li>c) En un formato legible por máquina</li>
                            <li>d) Tras el consentimiento del cliente</li>
                        </ol>
                    </Box>
                </Grid>
                <Grid item lg={5} md={6} sm={12}>
                    <Grid container spacing={2}>
                        <Grid item lg={6} md={6} sm={6} xs={6}>
                            <Box mt={ matches && '71px'}></Box>
                            <Box className={Styles.card}>
                                <h4>+60</h4>
                                <p>Clientes de Instituciones Financieras</p>
                            </Box>
                            <Box className={Styles.card}>
                                <h4>11k</h4>
                                <p>Desarrolladores</p>
                            </Box>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={6}> 
                            <Box className={Styles.card}>
                                <h4>5</h4>
                                <p>Reguladores</p>
                            </Box>
                            <Box className={Styles.card}>
                                <h4>20</h4>
                                <p>Partners</p>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>   
        </>
    )
}

export default Trayectoria
