import React from 'react';
import Heading from '../Heading/Heading';
import {Box, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Styles from './Conversacionales.module.scss';
import icon from '../../assets/images/chatbots/conversacionales.svg';

const Conversacionales = () => {
    const matches = useMediaQuery('(min-width:960px)');
    return (
        <>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item lg={5} md={6} sm={12}>
                    <Heading
                        subtitle="ChatBots"
                        title="Conversacionales"
                        description="Pueden resolver las necesidades de una persona. Un ejemplo de las actividades que puede realizar nuestro Chatbot mediante una conversación automatizada , sencilla y coloquial son:"
                    />
                    <img src={icon} alt="ChatBots Conversacionales" />
                </Grid>
                <Grid item lg={5} md={6} sm={12}>
                    <Box mt={ matches && '136px'}></Box>
                    <Box className={Styles.card}>
                        <h4>Actividades ChatBot</h4>
                        <ul>
                            <li>Registro</li>
                            <li>Consulta de Saldo</li>
                            <li>Consulta de Movimientos</li>
                            <li>Pago de Tarjetas </li>
                            <li>Transferencias propias y otras cuentas </li>
                            <li>Pago de CFE, Telmex, Telccel y otros</li>
                            <li>Conversión de Moneda </li>
                            <li>Preguntas Frecuentes</li>
                            <li>Entre otras funcionalidades</li>
                        </ul>
                    </Box>
                </Grid>
            </Grid>

        </>
    )
}

export default Conversacionales
