import React from 'react';
import Heading from '../Heading/Heading';
import { Grid, Box } from '@material-ui/core';

import Styles from './Porque.module.scss';
import thebanker from '../../assets/images/openbanking/thebanker-logo.png'
import wired from '../../assets/images/openbanking/wired-logo.png'
import tnw from '../../assets/images/openbanking/tnw-logo.png'
import americanbanker from '../../assets/images/openbanking/americanbanker-logo.png'
import bbc from '../../assets/images/openbanking/bbc-logo.png'
import finextra from '../../assets/images/openbanking/finextra-logo.png'
import forbes from '../../assets/images/openbanking/forbes-logo.png'

const Porque = () => {
    return (
        <>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item lg={6} md={6} sm={12}>
                   <Heading
                        subtitle="¿por qué"
                        title="Open Banking?"
                        separator= { true }
                        description="El Open Bank Project es una solución de API para  bancos y la comunidad de programadores alrededor."
                   />
                    <p className={Styles.text}>
                        1 Time to market - liderar el ecosistema fintech<br />
                        2 Cumplimiento Regulatorio<br />
                        3 Mantenerse al día con BaaS y BaaP
                    </p>
                </Grid>
                <Grid item lg={5} md={6} sm={12} xs={12}>
                    <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box className={Styles.card}>
                                <img src={thebanker} alt="The Banker" />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item lg={10} md={10} sm={12} xs={12}>
                            <Box className={Styles.card}>
                                <img src={wired} alt="Wired" />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Box className={Styles.card}>
                        <img src={tnw} alt="TNW" />
                    </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Box className={Styles.card}>
                        <img src={americanbanker} alt="American Banker" />
                    </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                    <Box className={Styles.card}>
                        <img src={bbc} alt="BBC" />
                    </Box>
                </Grid>
            </Grid> 
            <Grid container spacing={2} justifyContent="center">
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box className={Styles.card}>
                        <img src={finextra} alt="Finextra" />
                    </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box className={Styles.card}>
                        <img src={forbes} alt="Forbes" />
                    </Box>
                </Grid>
            </Grid>  
        </>
    )
}

export default Porque
