import React from 'react';
import Heading from '../Heading/Heading';
import { Box, Grid } from '@material-ui/core';

import Styles from './Funcionalidades.module.scss';
import funcionalidades from '../../assets/images/ndbank/funcionalidades.png';

const Funcionalidades = () => {
    return (
        <>
          <Heading
            subtitle="Nova Digital Bank"
            title="Funcionalidades"
            separator={ true }
          />
          <Box mt="135px"></Box>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item lg={6} md={6} sm={12}>
                <Box className={Styles.card}>
                    <ul className={Styles.list}>
                        <li> Mis cuentas</li>
                        <li>TransferencIas</li>
                        <li>Pagos</li>
                        <li>Impuestos</li>
                        <li>Inversiones</li>
                        <li>Cuenta Balance Cero</li>
                        <li>Nómina</li>
                        <li>Consultas y Reportes</li>
                        <li>Reportes para consulta y exportación </li>
                        <li>Archivos</li>
                        <li>Operaciones frecuentes</li>
                        <li>Consola de Administración</li>
                    </ul>
                </Box>
            </Grid>
            <Grid item lg={5} md={5} sm={12}>
                <img className={Styles.image} src={funcionalidades} alt="Funcionalidades Nova Digital Bank" />
            </Grid>
          </Grid>
        </>
    )
}

export default Funcionalidades
