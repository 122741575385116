import React from 'react';
import IntroText from '../IntroText/IntroText';
import Heading from '../Heading/Heading';
import Soluciones from '../Home/Soluciones';
import Clientes from '../Home/Clientes';
import Historia from './Historia';
import Porque from './Porque';
import Alianzas from './Alianzas';
import { Container, Box, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import nosotros from '../../assets/images/nosotros/nosotros.png';
import hacemos from '../../assets/images/nosotros/hacemos.png';
import bgNosotros from '../../assets/images/nosotros/background.svg';
import rebootNova from '../../assets/pdf/RebootNova.pdf'

const Nosotros = () => {
    const matches = useMediaQuery('(min-width:1280px)');
    return (
        <>
            <Box style={{
               backgroundImage: 'url('+ bgNosotros +')',
               backgroundRepeat: 'no-repeat',
               backgroundSize: '100% auto',
               backgroundPosition: 'left 1197px'
            }}>
                <Container maxWidth="lg">
                    <IntroText
                        marginTop={'104px'}
                        subtitle="Nova"
                        title="Nosotros"
                        normalParagraph="<strong>Somos generadores de experiencias digitales excepcionales, </strong>mediante la entrega de productos de rápida adopción, que aceleren el crecimiento del sector bancario, facilitando a estas instituciones sus objetivos de inclusión financiera y así mismo fomentando el crecimiento económico social de México."
                        boldParagraph="¡El éxito de nuestros clientes es nuestro éxito!"
                        image={ nosotros }
                        alt="Aceleramos la adopción digital en el sector financiero mexicano"
                    />
                    <Button variant="contained" color="primary" className="button-md" href={rebootNova} target='_blank' disableElevation>Descargar Brochure Nova en PDF</Button>
                    <Box id="hacemos">
                        <Heading
                            margin="242px"
                            subtitle="Solución integral"
                            title="¿Qué Hacemos?"
                            separator={ true }
                        />
                        <Box mt="20px">
                            <img src={ hacemos } alt="¿Qué hacemos?" style={{width:'100%'}} />
                        </Box>
                    </Box>
                    <Soluciones />
                    <Clientes
                        subtitle="Ellos confían en nosotros"
                        title="Nuestras soluciones ayudan a nuestros Clientes"
                        negativeMarginTop={ matches && '-70px'}
                    />
                    <Box id="historia"><Historia /></Box>
                    <Porque />
                    <Box id="alianzas"><Alianzas /></Box>
                </Container>
            </Box>
        </>
    )
}

export default Nosotros