import React from 'react';
import Heading from '../Heading/Heading';
import { Grid, Box } from '@material-ui/core';

import Styles from './Solucion.module.scss';
import omni from '../../assets/images/ndbank/experiencia.svg';
import perfila from '../../assets/images/ndbank/plataforma.svg';
import cross from '../../assets/images/ndbank/cloudnative.svg';
import cumple from '../../assets/images/ndbank/analytics.svg';

const Solucion = () => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item lg={5} md={6} sm={12} xs={12}>
                    <Heading
                        subtitle="Nova rao"
                        title="Nuestra solución"
                        separator={ true }
                        description="Nova RAO, una solución Out of the Box, Solución as a Service, personalizada al cliente que permite apertura de cuentas en línea."
                    />
                </Grid>
            </Grid>
            <Box mt="48px"></Box>
            <Grid container spacing={2}>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box className={Styles.card}>
                        <img src={omni} alt="Omnicanal" />
                        <h4>Omnicanal</h4>
                        <p>Save & Resume </p>
                        <ul>
                            <li>Recolecta Información</li>
                            <li>Guarda enun Storage</li>
                            <li>No duplica información</li>
                            <li>Up salle / Cross sale</li>
                            <li>Identificación omnicanal</li>
                            <li>DMP</li>
                        </ul>
                    </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box className={Styles.card}>
                        <img src={perfila} alt="Perfila" />
                        <h4>Perfila</h4>
                        <p>Cliente en tiempo real</p>
                        <ul>
                            <li> Escanea INE</li>
                            <li>Verifica Identidad</li>
                        </ul>
                    </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box className={Styles.card}>
                        <img src={cross} alt="Cross Sell" />
                        <h4>Cross Sell</h4>
                        <p>Perfil del usuario</p>
                        <ul>
                            <li>Video conferencia</li>
                            <li>Verifica Identidad</li>
                        </ul>
                    </Box>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box className={Styles.card}>
                        <img src={cumple} alt="Cumple con" />
                        <h4>Cumple con</h4>
                        <ul>
                            <li>Compliance</li>
                            <li>Regulación</li>
                            <li>KYC</li>
                            <li>SNBV</li>
                            <li>Buró de Crédito</li>
                            <li>AML</li>
                            <li>Historial crediticio</li>
                            <li>Listas negras del banco</li>
                        </ul>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Solucion
