import React from 'react';
import IntroText from '../IntroText/IntroText';
import Sdk from './Sdk';
import Arquitectura from './Arquitectura';
import Funcionalidades from './Funcionalidades';
import { Box, Container } from '@material-ui/core';

import background from '../../assets/images/codi/bg.svg';
import codi from '../../assets/images/codi/codi.png';

const Codi = () => {
    return (
        <>
            <Box pt="116px" pb="259px" style={{
                backgroundImage: 'url('+ background +')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% auto',
                backgroundPosition: 'left 639px'
            }}>
                <Container maxWidth="lg">
                    <IntroText
                        marginTop="105px"
                        subtitle="SDK"
                        title="CoDi"
                        normalParagraph="<strong>Solución que se integra con los servicios de transferencia de la Institución Bancaria así como con los servicios de BANXICO, permite el proceso de Pago para el Cliente, y de igual manera el proceso de cobro para el Comerciante. </strong>"
                        image={ codi }
                        alt="SDK CoDi"
                    />
                    <Box pt="360px"></Box>
                    <Sdk />
                    <Box pt="193px"></Box>
                    <Arquitectura />
                    <Box pt="360px"></Box>
                    <Funcionalidades />
                </Container>
            </Box>
        </>
    )
}

export default Codi