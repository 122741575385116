import React from 'react'
import { Grid } from '@material-ui/core'

import Styles from './IntroText.module.scss';

const IntroText = (props) => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12}>
                    <Grid container spacing={2}>
                        <Grid item lg={10} md={10}>
                            <h4 className={Styles.subtitle} style={{ marginTop: props.marginTop }}>
                                { props.subtitle }
                            </h4>
                            <h1 className={Styles.title}>
                                { props.title }
                            </h1>
                            <p className={Styles.paragraph} dangerouslySetInnerHTML={{__html: props.normalParagraph }}></p>
                            <p className={ Styles.extra }>
                                { props.boldParagraph }
                            </p>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={6} md={6} sm={12}>
                    <img src={ props.image } alt={ props.alt } className={ Styles.image } />
                </Grid>
            </Grid>
        </>
    )
}

export default IntroText
