import React from 'react';
import IntroText from '../IntroText/IntroText';
import Partners from './Partners';
import Trayectoria from './Trayectoria';
import Projectools from './Projectools';
import Porque from './Porque';
import { Box, Container } from '@material-ui/core';

import background from '../../assets/images/openbanking/bg.svg';
import openbanking from '../../assets/images/openbanking/open.png';

const Openbanking = () => {
    return (
        <>
            <Box id="soluciones" pt="117px" style={{
                backgroundImage: 'url('+ background +')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% auto',
                backgroundPosition: 'left 746px'
            }}>
                <Container maxWidth="lg">
                    <IntroText
                        marginTop={'104px'}
                        subtitle="Siempre adelantados"
                        title="Open Banking"
                        normalParagraph="<strong>Un movimiento mundial que alienta a las instituciones financieras a permitir el acceso de terceros autorizados por medio de APIs.</strong>"
                        image={ openbanking }
                        alt="Siempre adelantados: Open Banking"
                    />
                    <Box mb="272px"></Box>
                    <Partners />
                    <Box mb="328px"></Box>
                    <Trayectoria />
                    <Box mb="270px"></Box>
                    <Projectools />
                    <Box mb="224px"></Box>
                    <Porque />
                    <Box mb="213px"></Box>
                </Container>
            </Box>
        </>
    )
}

export default Openbanking
