import React from 'react';
import Heading from '../Heading/Heading';
import { Grid, Box } from '@material-ui/core';

import Styles from './Projectools.module.scss';
import project1 from '../../assets/images/openbanking/project1.png';
import project2 from '../../assets/images/openbanking/project2.png';
import project3 from '../../assets/images/openbanking/project3.png';

const Projectools = () => {
    return (
        <>
            <Heading
                subtitle="Open bank"
                title="Project Tools"
                separator={ true }
            />
            <Box mt="80px"></Box>
            <Grid container spacing={2}>
                <Grid item lg={4}  md={4} sm={12}>
                    <Box className={Styles.card}>
                        <img src={ project1 } alt="Branded Portal Desarrollo" />
                        <h4>Branded Portal Desarrollo</h4>
                        <p>Implementado en entornos sandbox y en vivo conectados a CBS</p>
                        <p>Habilitador de BaaS y BaaP y la infraestructura para agregar API y crear aplicaciones financieras</p>
                    </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={12}>
                    <Box className={Styles.card}>
                        <img src={ project2 } alt="API Exporer" />
                        <h4>API Exporer</h4>
                        <p>400+ API bancarias</p> 
                        <p>Flujos de autorización y consentimiento</p> 
                        <p>Interoperabilidad de estándares regulados</p>
                    </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={12}>
                    <Box className={Styles.card}>
                        <img src={ project3 } alt="API Manager" />
                        <h4>API Manager</h4>
                        <p>Roles, acceso y derechos de los solicitantes de datos</p>
                        <p>Métricas de API</p>
                        <p>Monetización de API</p>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Projectools
