import React from 'react';
import Somos from './Somos';
import Agiles from './Agiles';
import Soluciones from './Soluciones';
import Clientes from './Clientes';
import Bestplace from './Bestplace';
import Prensa from './Prensa';

import { Container } from '@material-ui/core';

const Home = () => {
    return (
        <>
            <Container maxWidth="lg">
                <Somos />
                <Agiles />
            </Container>
            <Soluciones />
            <Container maxWidth="lg">
                <Clientes
                    subtitle="Ellos confían en nosotros"
                    title="Nuestros Clientes"
                />
                <Bestplace />
                <Prensa />
            </Container>
        </>
    )
}

export default Home