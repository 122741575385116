import React from 'react'
import Heading from '../Heading/Heading';
import { Grid, Box } from '@material-ui/core';

import bestplace from '../../assets/images/home/bestplace.png';

const Bestplace = () => {
    return (
        <>
            <Box mb="264px">
                <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={12}>
                        <img src={bestplace} alt="Best Place to Code 2021" style={{display: 'block', width: '100%'}} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12}>
                        <Heading
                            margin='120px'
                            subtitle="Nova - Tech consulting"
                            title="Best Place to Code 2021"
                            separator={ false }
                            bold={ false }
                            description="En Nova estamos comprometidos en mantener un ambiente de respeto y apoyo, viviendo una cultura empresarial de igualdad de oportunidades, equidad, libre de discriminación, acoso e intimidación de cualquier tipo, respetando el espíritu y la intención de la Ley de Derechos Humanos"
                            button="Ver más"
                            to="/unete#bestplacetocode"
                    />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default Bestplace
