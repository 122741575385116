import React from 'react';
import Heading from '../Heading/Heading';
import { Grid, Box } from '@material-ui/core';

import Styles from './Diferenciadores.module.scss';

const Diferenciadores = () => {
    return (
        <>
            <Heading
                subtitle="Nuestra solución"
                title="Diferenciadores"
                separator={ true }
            />
            <Grid container spacing={2} justifyContent="center">
                <Grid item lg={10} md={10} sm={12}>
                   <Box className={Styles.card2}>
                       <Grid container spacing={2} justifyContent="center">
                            <Grid item lg={10} md={10} sm={12}>
                            <ul className={Styles.list}>
                                <li>Solución customer centric</li>
                                <li>Una misma plataforma para web y móvil,</li>
                                <li>Modern web architecture</li>
                                <li>Reducimos el time to market</li>
                                <li>Control de sus activos digitales. </li>
                                <li>Cuenta con un experience manager </li>
                                <li>Se puede administrar contenido estático y dinámico.</li>
                                <li>Reusable Journeys. </li>
                                <li>Legacy-flexible</li>
                                <li>Las APIs de nuestra solución siguen los layouts de Open Banking. <strong>(Integración TESOBE/Open Bank Project)</strong></li>
                            </ul>
                            </Grid>
                       </Grid>
                   </Box>
                </Grid>
            </Grid>   
        </>
    )
}

export default Diferenciadores
 