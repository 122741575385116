import React from 'react'
import Heading from '../Heading/Heading';
import HistoriaItem from './HistoriaItem';

import { Box, Grid } from '@material-ui/core'

const historia = () => {
    return (
        <>
            <Heading
                margin="0"
                subtitle="Key Milestones"
                title="Historia Nova"
                separator={ true }
            />
            <Box mb="89px"></Box>
            <HistoriaItem
                year="2015"
                alt="Fundación"
                title="Fundación"
                text="Nova es fundada en la Ciudad de México, en asociación con profesionales experimentados en el sector financiero digital."
            />
            <HistoriaItem
                year="2016"
                alt="Primeros Pasos"
                title="Primeros Pasos"
                text="● Comenzar operaciones con nuestro primer contrato.<br />
                ● Creación de nuestra plataforma bancaria Nova Digital Bank.<br />
                ● Cierra el año con 20 personas"
            />
            <HistoriaItem
                year="2017"
                alt="Avance firme"
                title="Avance firme"
                text="● Metodología ágil establecida como método de desarrollo interno<br />
                ● Toda la empresa fue certificada en SCRUM<br />
                ● Cierra el año con 45 personas"
            />
            <HistoriaItem
                year="2018"
                alt="Alcanzando metas"
                title="Alcanzando metas"
                text="● Creación de nuestras tres plataformas de desarrollo (móvil, web y microservicios)<br />
                ● Nova es premiada por Best Place to Code (11a posición)<br />
                ● Nova cambia su sede a Homework Co-working, Ciudad de México<br />
                ● Cierra el año con 95 personas"
            />
            <HistoriaItem
                year="2019"
                alt="Consolidando"
                title="Consolidando"
                text="● Nova está especializada en desarrollo ágil y transformación digital.<br />
                ● Estableció la Oficina de Guadalajara para apoyo nearshore a nuestros clientes<br />
                ● Estableció la oficina de Santa Fe, Ciudad de México para apoyo nearshore a nuestros clientes <br />
                ● Nova es Premiada por Best Place to Code (categoría de grandes empresas de 2ª posición)<br />
                ● Cierra el año con 264 personas"
            />
            <HistoriaItem
                year="2020"
                alt="Sumando logros"
                title="Sumando logros"
                text="● Nuestro WoW se reformula para el trabajo remoto<br />
                ● Iniciamos creación de nuestra solución de Apertura de Cuentas Remotas (RAO)<br />
                ● Fortalecemos nuestra oferta de Open Banking<br />
                ● Formamos parte de Reboot<br />
                ● Nova es Premiada por Best Place to Code (categoría de grandes empresas de 2ª posición)<br />
                ● Cierra el año con 320 personas"
            />
            <Grid container spacing={2}>
                <Grid item lg={2} md={2} sm={2}></Grid>
                <Grid item lg={3} md={3} sm={3} align="center">
                    <p style={{fontSize: '1.5rem', fontWeight: '700'}}>Vamos por mucho más</p>
                </Grid>
            </Grid>
            <Box mb="284px"></Box>
        </>
    )
}

export default historia
